import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message, Modal } from "antd";
import { RiCheckboxCircleLine, RiAlertLine, } from "react-icons/ri";
// Login Redux States
import {
  apiError,
  loginUserSuccessful,
  OTPRequestSuccessful,
  resendOTPSuccessful,
  showResend
} from "./loginSlice";

// AUTH related methods
import {
  postLogin,
  simplePost,
  postAdd,
  postLogout,
  postEdit
} from "../../../helpers/Helper";

// function setLoginCred(data){
//   localStorage.setItem("access_token", data.tokens?.access);
//   localStorage.setItem("refresh_token", data.tokens?.refresh);
//   localStorage.setItem("username", data.username);
//   localStorage.setItem("User_full_name", data.full_name);
//   // localStorage.setItem("first_name", response.data.first_name);
//   localStorage.setItem("email", data.email);
//   localStorage.setItem("phone", data.phone);
//   localStorage.setItem("device_uuid", data.device_uuid);
//   localStorage.setItem("userId", data?.id);
// }
//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload }) {
  try {
    const response = yield call(postLogin, "/users/login/", payload.loginData);
    if (response.status === 200) {
      yield put(loginUserSuccessful(response));
     
      if (response.data.is_default_password == true) {
        payload.history.push("/change_Password");
      } else if (response.data.is_default_password == false) {
        localStorage.setItem("access_token", response.data.tokens?.access);
        localStorage.setItem("refresh_token", response.data.tokens?.refresh);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("User_full_name", response.data.full_name);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("phone", response.data.phone);
        localStorage.setItem("device_uuid", payload.loginData.device_uuid);
        localStorage.setItem("userId", response.data?.id);
        message.success({
          content: "Login successfully!",
          icon: <RiCheckboxCircleLine className="remix-icon" />,
        });
        payload.history.push("/");
      }
    } else {
      alert("error");
    }
  } catch (error) {
    console.log("error", error);

    yield put(apiError(error?.data));
  }
}
function* OTPRequest({ payload }) {
  try {
    const response = yield call(simplePost, "/users/otprequest/", payload);
    console.log("response", response, response.status);
    if (response.status === 200) {
      // message.success({
      //   content: "OPT:" + response.data.otp,
      //   icon: <RiCheckboxCircleLine className="remix-icon" />,
      //   duration: 5,
      // });
      yield put(OTPRequestSuccessful(true));
    } else {
      alert("error");
      yield put(OTPRequestSuccessful(false));
    }
  } catch (error) {
    yield put(apiError(error?.data));
  }
}

function* logoutUser({ payload }) {
  localStorage.clear();
  // console.log("payload.history", payload);
  // payload.history.go(-(history.length - 1));
  try {
    if (payload.data.refresh != null) {
      const response = yield call(postLogout, "/users/logout/", payload.data);
      // console.log("response", response, response.status);
      payload.history.push("/login");
    } else {
      payload.history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function* ResendOTP({ payload }) {
  console.log("payload", payload);
  try {
    const response = yield call(simplePost, "/users/resendotp/", payload);
    console.log("response", response, response.status);
    if (response.status === 200) {
      // message.success({
      //   content: "OPT:" + response.data.otp,
      //   icon: <RiCheckboxCircleLine className="remix-icon" />,
      //   duration: 5,
      // });
      yield put(resendOTPSuccessful(true));
      yield put(showResend(false));
    } else {
      alert("error");
      yield put(resendOTPSuccessful(false));
    }
  } catch (error) {
    yield put(apiError(error?.data));
  }
}


function* ChangePassword({ payload }) {
  try {
    const response = yield call(postEdit, "/users/changepassword/", payload.data);
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Password Changed Successfully.!
            </p>
          </div>
        ),
      });
      payload.history.push("/login");

    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to change password. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.Message[0]}
            </p>
          </div>
        ),
      });
    } else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}


export function* watchUserLogin() {
  yield takeEvery("login/checkLogin", loginUser);
}
export function* watchGetOTPRequest() {
  yield takeEvery("login/getOTPRequest", OTPRequest);
}

export function* watchUserLogout() {
  yield takeEvery("login/logoutUser", logoutUser);
}

export function* watchGetResendOTP() {
  yield takeEvery("login/getResendOTP", ResendOTP);
}
export function* watchUserChangePwd() {
  yield takeEvery("login/userChangePwd", ChangePassword);
}
function* loginSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchGetOTPRequest),
    fork(watchUserLogout),
    fork(watchGetResendOTP),
    fork(watchUserChangePwd),
  ]);
}

export default loginSaga;
