import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getStates,
  stateListSuccessful,
  stateAddSuccessful,
  getStateByIDSuccessful,
  isModelVisible,
} from "./stateSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* getState({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/state/", params);
    yield put(stateListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddState({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/state/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  State Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStates({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add State. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(stateAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/state/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  State Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStates({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to State Country. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(stateAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* DeleteState({ payload }) {
  var stateID = payload;
  try {
    const response = yield call(postDelete, "/masters/state/" + stateID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                State has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getStates({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry!   State has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* StateByID({ payload }) {
  try {
    const response = yield call(getList, "/masters/state/" + payload, {});
    yield put(getStateByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchgetStates() {
  yield takeEvery("state/getStates", getState);
}

export function* watchStateAdd() {
  yield takeEvery("state/stateAdd", AddState);
}

export function* watchStateDelete() {
  yield takeEvery("state/stateDelete", DeleteState);
}
export function* watchGetStateByID() {
  yield takeEvery("state/getStateByID", StateByID);
}
function* StateSaga() {
  yield all([
    fork(watchgetStates),
    fork(watchStateAdd),
    fork(watchStateDelete),
    fork(watchGetStateByID),
  ]);
}

export default StateSaga;
