import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    employeeCount: {},
    presents: {},
    absent: {},
    late: {},
    leave: {},
    onsite: {},
    revised_present: {},
    revised_absent: {},


    taskList: [],
    taskOVerviewData: [],
    requestsData: [],
    attendanceData: [],
    categoty: [],
    taskParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    //
    userCategoty: [],
    userAttendanceData: [],
    userTaskOVerviewData: [],
    userRequestsData: [],
  },
  reducers: {
    clearData: (state, action) => {
      return {
        ...state,
        employeeCount: {},
        presents: {},
        absent: {},
        late: {},
        leave: {},
        taskOVerviewData: [],
        taskList: [],
        requestsData: [],
        attendanceData: [],
        categoty: [],
        taskParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },

        userCategoty: [],
        userAttendanceData: [],
        userTaskOVerviewData: [],
        userRequestsData: [],
      };
    },
    getEmployeeCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    employeeCountSuccessful: (state, action) => {
      var value = action.payload;
      return {
        ...state,
        employeeCount: { count: value.employees, arrowDown: false, type: " " },
        loading: false,
      };
    },

    getAttendanceCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    attendanceCountSuccessful: (state, action) => {
      var value = action.payload;
      var presentPer =
        ((value.present_count - value.yesterday_present_count) /
          state.employeeCount?.count) *
        100;

      var absentPer =
        ((value.absent_count - value.yesterday_absent_count) /
          state.employeeCount?.count) *
        100;

      var latePer =
        ((value.late_count - value.yesterday_late_count) /
          state.employeeCount?.count) *
        100;

      var leavePer =
        ((value.leave_count - value.yesterday_leave_count) /
          state.employeeCount?.count) *
        100;

      var onSitePer =
        ((value.onsite_count - value.yesterday_onsite_count) /
          state.employeeCount?.count) *
        100;
        

      // var leavePer =
      // ((parseInt(20)- parseInt(15)) / ( (parseInt(20) + parseInt(15)) / 2)*100).toFixed(1);

      return {
        ...state,
        presents: {
          count: value.present_count,
          yesterdayCount: value.yesterday_present_count,
          arrowDown: presentPer < 0 ? true : false,
          type: presentPer < 0 ? "danger" : "success",
          percentage: presentPer.toFixed(0),
        },
        absent: {
          count: value.absent_count,
          yesterdayCount: value.yesterday_absent_count,
          arrowDown: absentPer > 0 ? true : false,
          type: absentPer > 0 ? "danger" : "success",
          percentage: absentPer.toFixed(0),
        },
        late: {
          count: value.late_count,
          yesterdayCount: value.yesterday_late_count,
          arrowDown: latePer > 0 ? true : false,
          type: latePer > 0 ? "danger" : "success",
          percentage: latePer.toFixed(0),
        },
        leave: {
          count: value.leave_count,
          yesterdayCount: value.yesterday_leave_count,
          arrowDown: leavePer > 0 ? true : false,
          type: leavePer > 0 ? "danger" : "success",
          percentage: leavePer.toFixed(0),
        },
        onsite: {
          count: value.onsite_count,
          yesterdayCount: value.yesterday_onsite_count,
          arrowDown: onSitePer > 0 ? true : false,
          type: onSitePer > 0 ? "danger" : "success",
          percentage: onSitePer.toFixed(0),
        },




        revised_present: {
          count: value.total_present_count,
          // yesterdayCount: value.yesterday_onsite_count,
          // arrowDown: reviPresentPer > 0 ? true : false,
          // type: reviPresentPer > 0 ? "danger" : "success",
          // percentage: reviPresentPer.toFixed(0),
        },
        revised_absent: {
          count: value.total_absent_count,
          // yesterdayCount: value.yesterday_onsite_count,
          // arrowDown: reviAbsentPer > 0 ? true : false,
          // type: reviAbsentPer > 0 ? "danger" : "success",
          // percentage: reviAbsentPer.toFixed(0),
        },
        loading: false,
      };
    },

    getTaskOverView: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    taskOverViewSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        value.push({ title: key, total: data[key] });
        // value.splice(1, 0, { title: key, total: data[key] });
      });
      // value = [
      //   { title: "To Do", total: data.Todo, color: "#B473EF" },
      //   { title: "Process", total: data.Process, color: "#FCA15C" },
      //   { title: "Completed", total: data.completed, color: "#3B9CE3" },
      // ];
      return {
        ...state,
        taskOVerviewData: value,
        loading: false,
      };
    },

    getRequests: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    requestSuccessful: (state, action) => {
      // console.log("requestSuccessful", action.payload);
      var value = [];
      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //     value.push({ title: key, total: data[key] });
      // });
      value = [
        { title: "Onsite", total: data.onsite_count, color: "#71E2AE" },
        { title: "Leave", total: data.leave_request_count, color: "#50ABFF" },
        { title: "Late", total: data.late_request_count, color: "#B473EF" },
      ];
      return {
        ...state,
        requestsData: value,
        loading: false,
      };
    },

    getAttendanceData: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    attendanceDataSuccessful: (state, action) => {
      var value = [
        {
          name: "Absent",
          data: [],
        },
        {
          name: "Present",
          data: [],
        },
        {
          name: "Late",
          data: [],
        },
      ];
      var categoty = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        // console.log("categoty", categoty);
        // console.log("value", value);
        // console.log("data", data);
        categoty.push(key);

        value[0].data.push(data[key].Absent);
        value[1].data.push(data[key].Present);
        value[2].data.push(data[key].LateCount);
      });
      return {
        ...state,
        attendanceData: value,
        categoty: categoty,
        loading: false,
      };
    },

    getTaskList: (state, action) => {
      if (state.taskParams?.page == 1) {
        return {
          ...state,
          loading: true,
          taskList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    taskListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.taskParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        taskList: action.payload.response.results,
        loading: false,
        taskParams: {
          ...state.taskParams,
          no_of_pages: noofpages,
        },
      };
    },
    setTaskParams: (state, action) => {
      return {
        ...state,
        taskParams: action.payload,
      };
    },

    // User Dashboard
    getUserAttendance: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userAttendanceSuccessful: (state, action) => {
      console.log("userAttendanceSuccessful", action.payload);
      var value = [
        {
          name: "Absent",
          data: [],
        },
        {
          name: "Present",
          data: [],
        },
        {
          name: "Late",
          data: [],
        },
      ];
      var categoty = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        categoty.push(key);
        value[0].data.push(data[key].Absent);
        value[1].data.push(data[key].Present);
        value[2].data.push(data[key].LateCount);
      });
      return {
        ...state,
        userAttendanceData: value,
        userCategoty: categoty,
        loading: false,
      };
    },

    getUserTaskOverView: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userTaskOverViewSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        value.push({ title: key, total: data[key] });
        // value.splice(1, 0, { title: key, total: data[key] });
      });
      return {
        ...state,
        userTaskOVerviewData: value,
        loading: false,
      };
    },

    getUserRequests: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userRequestSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //     value.push({ title: key, total: data[key] });
      // });
      value = [
        { title: "Onsite", total: data.onsite_count, color: "#71E2AE" },
        { title: "Leave", total: data.leave_request_count, color: "#50ABFF" },
        { title: "Late", total: data.late_request_count, color: "#B473EF" },
      ];
      return {
        ...state,
        userRequestsData: value,
        loading: false,
      };
    },

    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  getEmployeeCount,
  employeeCountSuccessful,
  getAttendanceCount,
  attendanceCountSuccessful,
  getTaskOverView,
  taskOverViewSuccessful,
  getTaskList,
  taskListSuccessful,
  setTaskParams,
  getRequests,
  requestSuccessful,
  getAttendanceData,
  attendanceDataSuccessful,
  loadingStatus,
  apiError,

  //
  getUserAttendance,
  userAttendanceSuccessful,
  getUserTaskOverView,
  userTaskOverViewSuccessful,
  getUserRequests,
  userRequestSuccessful,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
