import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  holidyListSuccessful,
  getHolidayByIDSuccessful,
  getHolidayList,
  isModelVisible,
} from "./holidaySlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* HolidayListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/holiday/", params);
    yield put(holidyListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* HolidayByID({ payload }) {
  try {
    const response = yield call(getList, "/masters/holiday/" + payload, {});
    yield put(getHolidayByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddHoliday({ payload }) {
  try {
    if (payload.id == 0 || payload.id == undefined || payload.id == null) {
      const response = yield call(postAdd, "/masters/holiday/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Holiday  Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getHolidayList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Holiday . Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/holiday/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              Holiday Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getHolidayList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Holiday. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    console.log("error.response", error.response);
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.date[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
 
    yield put(apiError(error));
  }
}




function* DeleteHoliday({ payload }) {
  var stateID = payload;
  try {
    const response = yield call(postDelete, "/masters/holiday/" + stateID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Holiday has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getHolidayList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Holiday has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}





function* watchGetHolidayList() {
  yield takeEvery("holiday/getHolidayList", HolidayListGet);
}
function* watchGetHolidayByID() {
  yield takeEvery("holiday/getHolidayByID", HolidayByID);
}
function* watchHolidayAdd() {
  yield takeEvery("holiday/holidayAdd", AddHoliday);
}
export function* watchHolidayDelete() {
  yield takeEvery("holiday/holidayDelete", DeleteHoliday);
}
function* HolidaySaga() {
  yield all([
    fork(watchGetHolidayList),
    fork(watchGetHolidayByID),
    fork(watchHolidayAdd),
    fork(watchHolidayDelete),
  ]);
}

export default HolidaySaga;
