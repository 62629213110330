import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  apiError,
  getOrganization,
  organizationListSuccessful,
  organizationAddSuccessful,
  organizationByIdSuccessful,
  isModelVisible,
} from "./organizationSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* OrganizationGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/organizations/",
      params
    );
    yield put(organizationListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddOrganization({ payload }) {
  try {
    if (
      payload.obj.id == 0 ||
      payload.obj.id == null ||
      payload.obj.id == undefined
    ) {
      const response = yield call(
        postAdd,
        "/masters/organizations/",
        payload.obj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Organization Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getOrganization({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Organization. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(organizationAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/organizations/" + payload.obj.id + "/",
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Organization Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getOrganization({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Organization. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(organizationAddSuccessful(null));
    }
  } catch (error) {
    // Modal.error({
    //   icon: (
    //     <span className="remix-icon">
    //       <RiAlertLine />
    //     </span>
    //   ),

    //   title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //   content: (
    //     <div>
    //       <p className="hp-p1-body hp-text-color-black-80">
    //         Sorry! Getting from server side issue!
    //       </p>
    //     </div>
    //   ),
    // });
    yield put(apiError(error));
  }
}

function* DeleteOrganization({ payload }) {
  var id = payload;
  try {
    const response = yield call(
      postDelete,
      "/masters/organizations/" + id + "/"
    );
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Organization has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getOrganization({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry!   Organization has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* OrganizationById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/organizations/" + payload + "/",
      {}
    );
    yield put(organizationByIdSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetOrganization() {
  yield takeEvery("organization/getOrganization", OrganizationGet);
}
export function* watchOrganizationAdd() {
  yield takeEvery("organization/organizationAdd", AddOrganization);
}
export function* watchOrganizationById() {
  yield takeEvery("organization/organizationById", OrganizationById);
}
export function* watchOrganizationDeletee() {
  yield takeEvery("organization/organizationDelete", DeleteOrganization);
}

function* OrganizationSaga() {
  yield all([
    fork(watchGetOrganization),
    fork(watchOrganizationAdd),
    fork(watchOrganizationDeletee),
    fork(watchOrganizationById),
  ]);
}

export default OrganizationSaga;
