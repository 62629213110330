import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { message, Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

import {
  getPerformanceSuccessful,
  apiError,
  getPerformance,
} from "./monthlyPerformanceSlice";

function* getPerformanceData({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }

  try {
    const response = yield call(getParamsList, "/tasks/monthlykpi/", params);
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxBlankCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Monthly Performance processed Successfully!
          </p>
        </div>
      ),
    });
    yield put(getPerformanceSuccessful());
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Gettting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetPerFormance() {
  yield takeEvery("monthPerformance/getPerformance", getPerformanceData);
}

function* MonthlyPerformanceSaga() {
  yield all([fork(watchGetPerFormance)]);
}

export default MonthlyPerformanceSaga;
