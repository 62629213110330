import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniStatesList: [],
    miniCityList: [],
    miniDepartmentList: [],
    miniDesignationsList: [],
    miniRolesList: [],
    miniOrganizationList: [],
    miniLocationList: [],
    miniUserList: [],
    miniLeaveReasonList: [],
    miniOnSiteList: [],
    miniLateReasonList: [],
    miniReportingUserList: [],
    miniShiftAllocationList: [],
    miniWeekOffsListList: [],

    // Params
    miniStatesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniCityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDepartmentParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDesignationsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniRolesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniOrganizationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLocationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLeaveReasonParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniOnSiteParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLateReasonParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniReportingUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniShiftAllocationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniWeekOffsarams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniHolidayList: [],
    miniHolidayParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    // Wheelsmart Code
    miniCountryList: [],
    miniAreaList: [],
    miniDistrictList: [],
    miniCountersList: [],
    miniZonesList: [],
    miniClustersList: [],
    miniDivisionsList: [],
    miniManagersList: [],
  },
  reducers: {
    getUserPermissions: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userPermissionsSuccessful: (state) => {
      return {
        ...state,
        userAccessList: action.payload.response.data,
        loading: false,
      };
    },

    getMiniStates: (state) => {
      if (state.miniStatesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniStatesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniStatesSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniStatesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniStatesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniStatesParams?.page_size
      );
      return {
        ...state,
        miniStatesList: list,
        loading: false,
        miniStatesParams: {
          ...state.miniStatesParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        miniStatesParams: action.payload,
      };
    },
    getMiniStateClearData: (state) => {
      return {
        ...state,
        miniStatesList: [],
      };
    },
    getMiniCity: (state) => {
      if (state.miniCityParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniCityList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniCitySuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniCityParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniCityList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniCityParams?.page_size
      );
      return {
        ...state,
        miniCityList: list,
        loading: false,
        miniCityParams: {
          ...state.miniCityParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        miniCityParams: action.payload,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
      };
    },
    getMiniDepartment: (state) => {
      if (state.miniDepartmentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniDepartmentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniDepartmentSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniDepartmentParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniDepartmentList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniDepartmentParams?.page_size
      );
      return {
        ...state,
        miniDepartmentList: list,
        loading: false,
        miniDepartmentParams: {
          ...state.miniDepartmentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniDepartmentParams: (state, action) => {
      return {
        ...state,
        miniDepartmentParams: action.payload,
      };
    },
    getMiniDesignations: (state) => {
      if (state.miniDesignationsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniDesignationsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniDesignationSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniDesignationsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniDesignationsList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniDesignationsParams?.page_size
      );
      return {
        ...state,
        miniDesignationsList: list,
        loading: false,
        miniDesignationsParams: {
          ...state.miniDesignationsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniDesignationParams: (state, action) => {
      return {
        ...state,
        miniDesignationsParams: action.payload,
      };
    },

    getMiniRoles: (state) => {
      if (state.miniRolesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniRolesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniRoleSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniRolesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniRolesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniRolesParams?.page_size
      );
      return {
        ...state,
        miniRolesList: list,
        loading: false,
        miniRolesParams: {
          ...state.miniRolesParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniRolesParams: (state, action) => {
      return {
        ...state,
        miniRolesParams: action.payload,
      };
    },

    getMiniLocations: (state) => {
      if (state.miniLocationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniLocationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniLocationSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniLocationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniLocationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniLocationParams?.page_size
      );
      return {
        ...state,
        miniLocationList: list,
        loading: false,
        miniLocationParams: {
          ...state.miniLocationParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        miniLocationParams: action.payload,
      };
    },

    getMiniUsers: (state) => {
      if (state.miniUserParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniUserList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUserSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniUserParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniUserList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniUserParams?.page_size
      );
      return {
        ...state,
        miniUserList: list,
        loading: false,
        miniUserParams: {
          ...state.miniUserParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUserParams: (state, action) => {
      return {
        ...state,
        miniUserParams: action.payload,
      };
    },
    getMiniLeaveReason: (state) => {
      if (state.miniLeaveReasonParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniLeaveReasonList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniLeaveReasonSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniLeaveReasonParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniLeaveReasonList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniLeaveReasonParams?.page_size
      );
      return {
        ...state,
        miniLeaveReasonList: list,
        loading: false,
        miniLeaveReasonParams: {
          ...state.miniLeaveReasonParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniLeaveReasonParams: (state, action) => {
      return {
        ...state,
        miniLeaveReasonParams: action.payload,
      };
    },

    getMiniOnSite: (state) => {
      if (state.miniOnSiteParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniOnSiteList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniOnSiteSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniOnSiteParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniOnSiteList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniOnSiteParams?.page_size
      );
      return {
        ...state,
        miniOnSiteList: list,
        loading: false,
        miniOnSiteParams: {
          ...state.miniOnSiteParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniOnSiteParams: (state, action) => {
      return {
        ...state,
        miniOnSiteParams: action.payload,
      };
    },
    getMiniLateReason: (state) => {
      if (state.miniLateReasonParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniLateReasonList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniLateReasonSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniLateReasonParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniLateReasonList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniLateReasonParams?.page_size
      );
      return {
        ...state,
        miniLateReasonList: list,
        loading: false,
        miniLateReasonParams: {
          ...state.miniLateReasonParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniLateReasonParams: (state, action) => {
      return {
        ...state,
        miniLateReasonParams: action.payload,
      };
    },

    getMiniOrganizations: (state) => {
      if (state.miniOrganizationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniOrganizationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniOrganizationSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniOrganizationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniOrganizationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniOrganizationParams?.page_size
      );
      return {
        ...state,
        miniOrganizationList: list,
        loading: false,
        miniOrganizationParams: {
          ...state.miniOrganizationParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniOrganizationParams: (state, action) => {
      return {
        ...state,
        miniOrganizationParams: action.payload,
      };
    },

    getMiniReportingUser: (state) => {
      if (state.miniReportingUserParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniReportingUserList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniReportingUserSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniReportingUserParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniReportingUserList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniReportingUserParams?.page_size
      );
      return {
        ...state,
        miniReportingUserList: list,
        loading: false,
        miniReportingUserParams: {
          ...state.miniReportingUserParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniReportingUserParams: (state, action) => {
      return {
        ...state,
        miniReportingUserParams: action.payload,
      };
    },

    getMiniShiftAllocation: (state) => {
      if (state.miniShiftAllocationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniShiftAllocationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniShiftAllocationSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniShiftAllocationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniShiftAllocationList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniShiftAllocationParams?.page_size
      );
      return {
        ...state,
        miniShiftAllocationList: list,
        loading: false,
        miniShiftAllocationParams: {
          ...state.miniShiftAllocationParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniShiftAllocationParams: (state, action) => {
      return {
        ...state,
        miniShiftAllocationParams: action.payload,
      };
    },

    getMiniWeekOffsList: (state) => {
      if (state.miniWeekOffsarams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniWeekOffsListList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniWeekOffsListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniWeekOffsarams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniWeekOffsListList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniWeekOffsarams?.page_size
      );
      return {
        ...state,
        miniWeekOffsListList: list,
        loading: false,
        miniWeekOffsarams: {
          ...state.miniWeekOffsarams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniWeekOffsParams: (state, action) => {
      return {
        ...state,
        miniWeekOffsarams: action.payload,
      };
    },

    getMiniHolidayList: (state) => {
      if (state.miniHolidayParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniHolidayList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniHolidayListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniHolidayParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniHolidayList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniHolidayParams?.page_size
      );
      return {
        ...state,
        miniHolidayList: list,
        loading: false,
        miniHolidayParams: {
          ...state.miniHolidayParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniHolidayParams: (state, action) => {
      return {
        ...state,
        miniHolidayParams: action.payload,
      };
    },

    // Wheelsmart Code

    getMiniArea: (state) => {
      return {
        ...state,
        miniAreaList: [],
        loading: true,
      };
    },
    miniAreaSuccessful: (state, action) => {
      return {
        ...state,
        miniAreaList: action.payload,
        loading: false,
      };
    },
    getMiniAreaClearData: (state) => {
      return {
        ...state,
        miniAreaList: [],
      };
    },
    getMiniCounters: (state) => {
      return {
        ...state,
        miniCountersList: [],
        loading: true,
      };
    },
    miniCountersSuccessful: (state, action) => {
      return {
        ...state,
        miniCountersList: action.payload,
        loading: false,
      };
    },
    getMiniCountersClearData: (state) => {
      return {
        ...state,
        miniCountersList: [],
      };
    },
    getMiniZones: (state) => {
      return {
        ...state,
        miniZonesList: [],
        loading: true,
      };
    },
    miniZonesSuccessful: (state, action) => {
      return {
        ...state,
        miniZonesList: action.payload,
        loading: false,
      };
    },
    getMiniZonesClearData: (state) => {
      return {
        ...state,
        miniZonesList: [],
      };
    },

    getMiniClusters: (state) => {
      return {
        ...state,
        miniClustersList: [],
        loading: true,
      };
    },
    miniClustersSuccessful: (state, action) => {
      return {
        ...state,
        miniClustersList: action.payload,
        loading: false,
      };
    },
    getMiniClustersClearData: (state) => {
      return {
        ...state,
        miniClustersList: [],
      };
    },
    getMiniDivisions: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
        loading: true,
      };
    },

    miniDivisionsSuccessful: (state, action) => {
      return {
        ...state,
        miniDivisionsList: action.payload,
        loading: false,
      };
    },
    getMiniDivisionsClearData: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
      };
    },
    getMiniManagers: (state) => {
      return {
        ...state,
        miniManagersList: [],
        loading: true,
      };
    },
    miniManagersSuccessful: (state, action) => {
      return {
        ...state,
        miniManagersList: action.payload,
        loading: false,
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserPermissions,
  userPermissionsSuccessful,
  getMiniStates,
  miniStatesSuccessful,
  setMiniStatesParams,
  getMiniStateClearData,
  getMiniCity,
  miniCitySuccessful,
  setMiniCityParams,
  getMiniDepartment,
  miniDepartmentSuccessful,
  setMiniDepartmentParams,
  getMiniDesignations,
  miniDesignationSuccessful,
  setMiniDesignationParams,
  getMiniRoles,
  miniRoleSuccessful,
  setMiniRolesParams,
  getMiniOrganizations,
  miniOrganizationSuccessful,
  setMiniOrganizationParams,
  getMiniLocations,
  miniLocationSuccessful,
  setMiniLocationParams,
  getMiniUsers,
  miniUserSuccessful,
  setMiniUserParams,
  getMiniLeaveReason,
  miniLeaveReasonSuccessful,
  setMiniLeaveReasonParams,
  getMiniOnSite,
  miniOnSiteSuccessful,
  setMiniOnSiteParams,
  getMiniLateReason,
  miniLateReasonSuccessful,
  setMiniLateReasonParams,
  getMiniCityClearData,
  getMiniArea,
  miniAreaSuccessful,
  getMiniAreaClearData,
  getMiniReportingUser,
  miniReportingUserSuccessful,
  setMiniReportingUserParams,
  getMiniShiftAllocation,
  miniShiftAllocationSuccessful,
  setMiniShiftAllocationParams,
  getMiniWeekOffsList,
  miniWeekOffsListSuccessful,
  setMiniWeekOffsParams,
  getMiniHolidayList,
  miniHolidayListSuccessful,
  setMiniHolidayParams,

  // Wheelsmart Code
  getMiniCounters,
  miniCountersSuccessful,
  getMiniCountersClearData,
  getMiniZones,
  miniZonesSuccessful,
  getMiniZonesClearData,
  getMiniClusters,
  miniClustersSuccessful,
  getMiniClustersClearData,
  getMiniDivisions,
  miniDivisionsSuccessful,
  getMiniDivisionsClearData,
  getMiniManagers,
  miniManagersSuccessful,
  apiError,
} = miniSlice.actions;

export default miniSlice.reducer;
