import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message, Modal } from "antd";
import {
  apiError,
  getUserSuccessful,
  changePasswordModalShow,
  getActivityLogSuccessful,
  getUserDeviceListSuccessful,
} from "./profileSlice";
import { getList, postEdit, getParamsList } from "../../helpers/Helper";
import Swal from "sweetalert2";
import { RiCheckboxCircleLine, RiAlertLine, } from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* getUserlist() {
  try {
    const response = yield call(getList, "/users/iamuser/", {});
    yield put(getUserSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* UserChangePassword({ payload }) {
  try {
    const response = yield call(postEdit, "/users/changepassword/", payload.data);
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Password Changed Successfully.!
            </p>
          </div>
        ),
      });
      payload.history.push("/login");
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to change password. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      var msg = ""
      if(error.response.data.old_password){
        // msg = "Your old password is incorrect"
        msg = error.response.data.old_password.join(', ')
      }else if(error.response.data.Message){
        msg = error.response.data.Message[0]
      }else{
        msg = "Please check your input"
      }
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>

            <p className="hp-p1-body hp-text-color-black-80">
              {msg}
            </p>
          </div>
        ),
      });
    } else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

// function* addMenu({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");

//     history.push("/login");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* ActivityListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/devicelog/me/", params);
    yield put(getActivityLogSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UserDeviceListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/userdevices/me", params);
    yield put(getUserDeviceListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserList() {
  yield takeEvery("userProfile/getUser", getUserlist);
}
export function* watchUserChangePassword() {
  yield takeEvery("userProfile/ChangePasswordSubmit", UserChangePassword);
}
export function* watchGetActivityList() {
  yield takeEvery("userProfile/getActivityLog", ActivityListGet);
}
export function* watchGetUserDeviceList() {
  yield takeEvery("userProfile/getUserDeviceList", UserDeviceListGet);
}

// export function* watchAddMenu() {
//   yield takeEvery(ADD_MENU, addMenu);
// }

function* userProfileSaga() {
  yield all([
    fork(watchUserList),
    fork(watchUserChangePassword),
    fork(watchGetActivityList),
    fork(watchGetUserDeviceList),
  ]);
}

export default userProfileSaga;
