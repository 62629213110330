import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  userPermissionsSuccessful,
  miniStatesSuccessful,
  miniCitySuccessful,
  miniDepartmentSuccessful,
  miniDesignationSuccessful,
  miniRoleSuccessful,
  miniOrganizationSuccessful,
  miniLocationSuccessful,
  miniUserSuccessful,
  miniLeaveReasonSuccessful,
  miniOnSiteSuccessful,
  miniLateReasonSuccessful,
  miniReportingUserSuccessful,
  miniShiftAllocationSuccessful,
  miniWeekOffsListSuccessful,
  miniHolidayListSuccessful,

  // Wheelsmart Code
  miniAreaSuccessful,
  miniCountersSuccessful,
  miniZonesSuccessful,
  miniClustersSuccessful,
  miniDivisionsSuccessful,
  miniManagersSuccessful,
} from "./miniSlice";

// AUTH related methods
import { getList, getParamsList } from "../../helpers/Helper";
function* getUserPermissions() {
  try {
    const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniStatesGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/states/", params);
    yield put(miniStatesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniCityGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }

  try {
    console.log(params);
    const response = yield call(getParamsList, "/masters/mini/city/", params);
    yield put(miniCitySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniDepartmentGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/departments/",
      params
    );
    yield put(miniDepartmentSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniDesignationsGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/designations/",
      params
    );
    yield put(miniDesignationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniRolesGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/roles/", params);
    yield put(miniRoleSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniOrganizationsGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/organizations/",
      params
    );
    yield put(miniOrganizationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniLocationsGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/locations/",
      params
    );
    yield put(miniLocationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniUsersGet({ payload }) {
  console.log("MiniUsersGet", payload);
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/mini/users/", params);
    yield put(miniUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getLeaveReason({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/leave_reasons/",
      params
    );
    yield put(miniLeaveReasonSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniOnSiteGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  console.log(params);
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/onsite_locations/",
      params
    );
    yield put(miniOnSiteSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getLateReason({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/late_reasons/",
      params
    );
    yield put(miniLateReasonSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniReportingUserGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/mini/reporting_users/",
      params
    );
    yield put(miniReportingUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniShiftAllocation({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/shifts/", params);
    yield put(miniShiftAllocationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniWeekOffsList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/week_offs/",
      params
    );
    yield put(miniWeekOffsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniHolidayList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/holiday/",
      params
    );
    yield put(miniHolidayListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Wheelsmart Code

function* getMiniAreas({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/area/", data);
    yield put(miniAreaSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getCounters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/counters/",
      params
    );
    yield put(miniCountersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getZones({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/zones/", params);
    yield put(miniZonesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getClusters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/clusters/",
      params
    );
    yield put(miniClustersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getDivisions({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/divisions/",
      params
    );
    yield put(miniDivisionsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getManagers() {
  try {
    const response = yield call(
      getParamsList,
      "/permissions/mini/managers/",
      {}
    );
    yield put(miniManagersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetUserPermissions() {
  yield takeEvery("mini/getUserPermissions", getUserPermissions);
}
export function* watchGetMiniStates() {
  yield takeEvery("mini/getMiniStates", MiniStatesGet);
}
export function* watchGetMiniCity() {
  yield takeEvery("mini/getMiniCity", MiniCityGet);
}
export function* watchGetMiniDepartment() {
  yield takeEvery("mini/getMiniDepartment", MiniDepartmentGet);
}
export function* watchGetMiniDesignations() {
  yield takeEvery("mini/getMiniDesignations", MiniDesignationsGet);
}
export function* watchGetMiniRoles() {
  yield takeEvery("mini/getMiniRoles", MiniRolesGet);
}
export function* watchGetMiniOrganizations() {
  yield takeEvery("mini/getMiniOrganizations", MiniOrganizationsGet);
}
export function* watchGetMiniLocations() {
  yield takeEvery("mini/getMiniLocations", MiniLocationsGet);
}
export function* watchGetMiniUsers() {
  yield takeEvery("mini/getMiniUsers", MiniUsersGet);
}
export function* watchGetMiniLeaveReason() {
  yield takeEvery("mini/getMiniLeaveReason", getLeaveReason);
}
export function* watchGetMiniOnSite() {
  yield takeEvery("mini/getMiniOnSite", MiniOnSiteGet);
}
export function* watchGetMiniLateReason() {
  yield takeEvery("mini/getMiniLateReason", getLateReason);
}

export function* watchGetMiniReportingUser() {
  yield takeEvery("mini/getMiniReportingUser", MiniReportingUserGet);
}
export function* watchGetMiniShiftAllocation() {
  yield takeEvery("mini/getMiniShiftAllocation", MiniShiftAllocation);
}
export function* watchGetMiniWeekOffsList() {
  yield takeEvery("mini/getMiniWeekOffsList", MiniWeekOffsList);
}
export function* watchGetMiniHolidayList() {
  yield takeEvery("mini/getMiniHolidayList", MiniHolidayList);
}
// Wheelsmart Code

export function* watchGetAreas() {
  yield takeEvery("mini/getMiniArea", getMiniAreas);
}

export function* watchgetMiniCounters() {
  yield takeEvery("mini/getMiniCounters", getCounters);
}

export function* watchgetMiniZones() {
  yield takeEvery("mini/getMiniZones", getZones);
}
export function* watchgetMiniClusters() {
  yield takeEvery("mini/getMiniClusters", getClusters);
}
export function* getMiniDivisions() {
  yield takeEvery("mini/getMiniDivisions", getDivisions);
}
export function* watchGetMiniManagers() {
  yield takeEvery("mini/getMiniManagers", getManagers);
}

function* miniSaga() {
  yield all([
    fork(watchGetUserPermissions),
    fork(watchGetMiniStates),
    fork(watchGetMiniCity),
    fork(watchGetMiniDepartment),
    fork(watchGetMiniDesignations),
    fork(watchGetMiniRoles),
    fork(watchGetMiniOrganizations),
    fork(watchGetMiniLocations),
    fork(watchGetMiniUsers),
    fork(watchGetMiniLeaveReason),
    fork(watchGetMiniOnSite),
    fork(watchGetMiniLateReason),
    fork(watchGetMiniReportingUser),
    fork(watchGetMiniShiftAllocation),
    fork(watchGetMiniWeekOffsList),
    fork(watchGetMiniHolidayList),

    // Wheelmart Code
    fork(watchGetAreas),
    fork(watchgetMiniCounters),
    fork(watchgetMiniZones),
    fork(watchgetMiniClusters),
    fork(getMiniDivisions),
    fork(watchGetMiniManagers),
  ]);
}

export default miniSaga;
