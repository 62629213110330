import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  getLocationManager,
  locationManagerSuccessful,
  locationManagerbyIdSuccessful,
  isModelVisible,
} from "./locationManagerSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* locationManagerList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/permissions/locationmanagers/",
      params
    );
    yield put(locationManagerSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* addLocationManager({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/permissions/locationmanagers/",
        payload
      );

      if (response.status == 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Location Managers Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getLocationManager({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Location Managers. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response2 = yield call(
        postEdit,
        "/permissions/locationmanagers/" + payload.id,
        payload
      );

      if (response2.status == 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Location Managers Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getLocationManager({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Location Managers. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (errors) {
    yield put(apiError(errors));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
  }
}
function* getLocationManagerbyId({ payload }) {
  try {
    const response = yield call(
      getList,
      "/permissions/locationmanagers/" + payload,
      {}
    );
    yield put(locationManagerbyIdSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* deleteLocationManage({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/permissions/locationmanagers/" + payload
    );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Location Manager has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getLocationManager());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Location Manager has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchGetLocationManager() {
  yield takeEvery("locationManager/getLocationManager", locationManagerList);
}
export function* watchLocationManagerAdd() {
  yield takeEvery("locationManager/locationManagerAdd", addLocationManager);
}
export function* watchLocationManagerbyId() {
  yield takeEvery(
    "locationManager/locationManagerbyId",
    getLocationManagerbyId
  );
}
export function* watchLocationManagerDelete() {
  yield takeEvery(
    "locationManager/locationManagerDelete",
    deleteLocationManage
  );
}

function* LocationManagerSaga() {
  yield all([
    fork(watchGetLocationManager),
    fork(watchLocationManagerAdd),
    fork(watchLocationManagerbyId),
    fork(watchLocationManagerDelete),
  ]);
}

export default LocationManagerSaga;
