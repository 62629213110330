import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import { apiError, attendanceListSuccessful } from "./attendanceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* AttendanceList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k != "no_of_pages" ) {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/daily_attendance/",
      params
    );
    yield put(attendanceListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetAttendanceList() {
  yield takeEvery("attendance/getAttendanceList", AttendanceList);
}

function* AttendanceSaga() {
  yield all([fork(watchGetAttendanceList)]);
}

export default AttendanceSaga;
