import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  MasterMenuItemsSuccessful,
  sideMenuItemsSuccessful,
  ReportsMenuItemsSuccessful,
  userPermissionsSuccessful,
  QuickMenuItemsSuccessful,
  ImportMenuItemsSuccessful,
  profileMenuItemsSuccessful,
  ExportMenuItemsSuccessful,
  systemFormulasSuccessful,
} from "./systemSlice";

// AUTH related methods
import { getList, getParamsList, getDownloadFile } from "../../helpers/Helper";
function* MasterMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0003", {});
    yield put(MasterMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SideMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0004", {});
    yield put(sideMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ReportsMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0005", {});
    yield put(ReportsMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* UserPermissions() {
  try {
    const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* QuickMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0006", {});
    yield put(QuickMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ImportMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0007", {});
    yield put(ImportMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ProfileMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0008", {});
    yield put(profileMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ExportMenuItems() {
  try {
    const response = yield call(getList, "/system/user_menu/MENU0009", {});
    yield put(ExportMenuItemsSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SystemFormulas() {
  try {
    const response = yield call(getList, "/system/allformuls/", {});
    yield put(systemFormulasSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* DownloadFile({ payload }) {
  try {
    const response = yield call(getDownloadFile, payload);
    console.log("DownloadFile", response);
    // yield put(systemFormulasSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchgetMasterMenuItems() {
  yield takeEvery("system/getMasterMenuItems", MasterMenuItems);
}
export function* watchGetSideMenuItems() {
  yield takeEvery("system/getSideMenuItems", SideMenuItems);
}
export function* watchGetReportsMenuItems() {
  yield takeEvery("system/getReportsMenuItems", ReportsMenuItems);
}
export function* watchGetUserPermissions() {
  yield takeEvery("system/getUserPermissions", UserPermissions);
}
export function* watchGetQuickMenuItems() {
  yield takeEvery("system/getQuickMenuItems", QuickMenuItems);
}
export function* watchGetImportMenuItems() {
  yield takeEvery("system/getImportMenuItems", ImportMenuItems);
}
export function* watchGetProfileMenuItems() {
  yield takeEvery("system/getProfileMenuItems", ProfileMenuItems);
}
export function* watchGetExportMenuItems() {
  yield takeEvery("system/getExportMenuItems", ExportMenuItems);
}
export function* watchGetSystemFormulas() {
  yield takeEvery("system/getSystemFormulas", SystemFormulas);
}
export function* watchGetDownloadFile() {
  yield takeEvery("system/getDownloadFile", DownloadFile);
}

function* menuItemSaga() {
  yield all([
    fork(watchgetMasterMenuItems),
    fork(watchGetSideMenuItems),
    fork(watchGetReportsMenuItems),
    fork(watchGetUserPermissions),
    fork(watchGetQuickMenuItems),
    fork(watchGetImportMenuItems),
    fork(watchGetProfileMenuItems),
    fork(watchGetExportMenuItems),
    fork(watchGetSystemFormulas),
    fork(watchGetDownloadFile),
  ]);
}

export default menuItemSaga;
