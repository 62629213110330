import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    // lateReasonModel: false,
    rowdata: {},
    attendanceList: [],
    attendanceParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    RejectMode: false,
    filterStatus: false,
    rejectReasonObjData: {},
  },
  reducers: {
    getAttendanceList: (state, action) => {
      if (state.attendanceParams?.page == 1) {
        return {
          ...state,
          loading: true,
          attendanceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    attendanceListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.attendanceParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        // attendanceList: [
        //   ...state.attendanceList,
        //   ...action.payload.response.results,
        // ],
        attendanceList: action.payload.response.results,
        loading: false,
        attendanceParams: {
          ...state.attendanceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAttendanceParams: (state, action) => {
      return {
        ...state,
        attendanceParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        attendanceParams: {
          ...state.attendanceParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // InputChangeValue: (state, action) => {
    //    return {
    //         ...state,
    //         attendanceParams: {
    //           ...state.attendanceParams,
    //           [action.payload.key]: action.payload.value,
    //         },
    //   }
    // },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        purchaseRejectData: {},
      };
    },
    clearAttendance: (state, action) => {
      return {
        ...state,
        loading: true,
        attendanceList: [],
        filterStatus: false,
        attendanceParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAttendanceList,
  attendanceListSuccessful,
  isLateReasonModel,
  setAttendanceParams,
  // InputChangeValue,
  isRejectModelVisible,
  apiError,
  isDrawerVisible,
  FilterInputChangeValue,
  checkFilterStatus,
  clearAttendance,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
