import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getOnSiteList,
  onSiteListSuccessful,
  getOnSiteByIdSuccessful,
  isOnSiteAddModel,
  OnSiteApproveCheckSuccessful,
  IsAddApprovedModel,
  OnSiteViewApproveSuccessful,
  onSiteApproveSuccessful,
} from "./onSiteSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* OnSiteListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date" || k == "end_date") {
            params['date_multi_range'] = moment(data["start_date"]).format("YYYY-MM-DD") +","+ moment(data["end_date"]).format("YYYY-MM-DD");
          // } else if (k == "end_date") {
          //   params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    console.log("params", params);
    const response = yield call(getParamsList, "/attendance/onsite/", params);
    yield put(onSiteListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* OnSiteById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/onsite/" + payload.row.id,
      {}
    );
    yield put(
      getOnSiteByIdSuccessful({ response: response.data, type: payload.type })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddOnSite({ payload }) {
  let onSiteParams =  yield select((state) => state.onSite.onSiteParams);
  try {
    if (
      payload.obj.id == 0 ||
      payload.obj.id == undefined ||
      payload.obj.id == null
    ) {
      const response = yield call(postAdd, "/attendance/onsite/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Onsite Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/onSite");
        yield put(getOnSiteList({ page: 1, search: "", page_size: 10 }));
        yield put(isOnSiteAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Onsite. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/attendance/onsite/update/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Onsite Edited Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/onSite");
        yield put(getOnSiteList(onSiteParams));
        yield put(isOnSiteAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Onsite. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // yield put(addPurchaseSuccessful(null));
  } catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
   
    // yield put(addPurchaseSuccessful(null));
    yield put(apiError(error));
  }
}

function* DeleteOnSite({ payload }) {
  let onSiteParams =  yield select((state) => state.onSite.onSiteParams);
  try {
    const response = yield call(postDelete, "/attendance/onsite/" + payload.id);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Onsite has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getOnSiteList(onSiteParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Onsite has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* OnSiteApproveCheck({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/onsite/check_approve/" + payload.id,
      {}
    );
    if (response.status === 204) {
      yield put(OnSiteApproveCheckSuccessful(true));
    } else {
      yield put(OnSiteApproveCheckSuccessful(false));
    }
  } catch (error) {
    yield put(apiError(error));
    yield put(OnSiteApproveCheckSuccessful(false));
  }
}

// Onsite list & grid approvals

function* fetchAllData(ids, approveObj) {
  var successCount = 0;
  var failedCount = 0;
  for (const obj of ids) {
    try {
      const result = yield call(postEdit, `/attendance/onsite/approve/${obj.id}`, approveObj);
      console.log("result", result)
      if (result.status === 200) {
        successCount++;
      } else {
        failedCount++;
      }
    } catch (error) {
      failedCount++;
    }
  }
  return { successCount, failedCount };
}
function* ApproveOnSite({ payload }) {
  console.log("saga", payload)
  let onSiteParams =  yield select((state) => state.onSite.onSiteParams);
  try {
    const { id, approveObj } = payload;
    const { successCount, failedCount } = yield call(fetchAllData, id, approveObj);
        if (approveObj.onsite_status == 2) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                 {successCount} Onsite  Approved Successfully. & {failedCount} Onsite Failed to Approve
                </p>
              </div>
            ),
          });
        } else if (approveObj.onsite_status == 3) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {successCount} Onsite  Rejected Successfully. & {failedCount} Onsite Failed to Reject
                </p>
              </div>
            ),
          });
        }
        yield put(onSiteApproveSuccessful());
        yield put(getOnSiteList(onSiteParams));
     
  } catch (error) {
    yield put(apiError(error));
  }
}

// View Onsite approve
function* ApproveViewOnSite({ payload }) {
  let onSiteParams =  yield select((state) => state.onSite.onSiteParams);
  try {
    const response = yield call(
      postEdit,
      "/attendance/onsite/approve/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.onsite_status == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                 Onsite Approved Successfully.!
              </p>
            </div>
          ),
        });
      }else if(payload.approveObj.onsite_status == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                 Onsite has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(OnSiteViewApproveSuccessful(response));
      yield put(
        getOnSiteList(onSiteParams)
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Onsite. Please try again!
            </p>
          </div>
        ),
      });
    }

   
  } catch (error) {
    yield put(apiError(error));
  }
}



function* AddOnSiteApproved({ payload }) {
  let onSiteParams =  yield select((state) => state.onSite.onSiteParams);
  try {
    const response = yield call(
      postAdd,
      "/attendance/admin/onsite/",
      payload.obj
    );
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Onsite Approved Added Successfully.!
            </p>
          </div>
        ),
      });
      payload.history.push("/pages/onSite");
      yield put(getOnSiteList(onSiteParams));
      yield put(IsAddApprovedModel(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Onsite Approved. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}

export function* watchGetOnSiteList() {
  yield takeEvery("onSite/getOnSiteList", OnSiteListGet);
}
export function* watchGetOnSiteById() {
  yield takeEvery("onSite/getOnSiteById", OnSiteById);
}
export function* watchOnSiteDelete() {
  yield takeEvery("onSite/onSiteDelete", DeleteOnSite);
}
export function* watchOnSiteAdd() {
  yield takeEvery("onSite/onSiteAdd", AddOnSite);
}
export function* watchCheckOnSiteApprove() {
  yield takeEvery("onSite/CheckOnSiteApprove", OnSiteApproveCheck);
}
export function* watchOnSiteApprove() {
  yield takeEvery("onSite/onSiteApprove", ApproveOnSite);
}
export function* watchOnSiteViewApprove() {
  yield takeEvery("onSite/OnSiteViewApprove", ApproveViewOnSite);
}
export function* watchOnSiteApprovedAdd(){
  yield takeEvery("onSite/onSiteApprovedAdd", AddOnSiteApproved)
}

function* OnSiteSaga() {
  yield all([
    fork(watchGetOnSiteList),
    fork(watchGetOnSiteById),
    fork(watchOnSiteDelete),
    fork(watchOnSiteAdd),
    fork(watchCheckOnSiteApprove),
    fork(watchOnSiteApprove),
    fork(watchOnSiteApprovedAdd),
    fork(watchOnSiteViewApprove),
  ]);
}

export default OnSiteSaga;
