import { createSlice } from "@reduxjs/toolkit";

export const departmentSlice = createSlice({
  name: "departments",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    departmentslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    departmentsParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getDepartments: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    departmentListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.departmentsParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        departmentslist: action.payload.response.results,
        loading: false,
        departmentsParams: {
          ...state.departmentsParams,
          no_of_pages: noofpages,
        },
      };
    },

    departmentAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    departmentAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },

    getDeptByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getDeptByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        },
      };
    },
    departmentDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetDepartmentsParams: (state, action) => {
      return {
        ...state,
        departmentsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDepartments,
  departmentListSuccessful,
  departmentAdd,
  departmentAddSuccessful,
  getDeptByID,
  getDeptByIDSuccessful,
  departmentDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetDepartmentsParams
} = departmentSlice.actions;

export default departmentSlice.reducer;
