import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const attendCorrectionSlice = createSlice({
  name: "AttendCorrection",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    attendancedata: {},
    attendCorreList: [],
    attendCorreParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    filterStatus: false,
    
  },
  reducers: {
    // Attendance type user api
    clearData: (state, action) => {
      return {
        ...state,
        loading: false,
        attendancedata: {},
        attendCorreList: [],
        filterStatus: false,
      };
    },

    getAttendCorrection: (state) => {
      if (state.attendCorreParams?.page == 1) {
        return {
          ...state,
          loading: true,
          attendCorreList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    attendCorrectionSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.attendCorreParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.attendCorreParams?.page_size
      );
      return {
        ...state,
        attendCorreList: list,
        listCount: action.payload.count,
        loading: false,
        attendCorreParams: {
          ...state.attendCorreParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAttendCorrectionParams: (state, action) => {
      return {
        ...state,
        attendCorreParams: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          attendancedata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          attendancedata: {},
        };
      }
    },





    attendanceAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    attendanceAddSuccessfully: (state, action) => {
      return {
        ...state,
        loading: false,
        model: false,
        attendancedata: {},
      };
    },
    InputChangeValue: (state, action) => {
        return {
          ...state,
          attendancedata: {
            ...state.attendancedata,
            [action.payload.key]: action.payload.value,
          },
        };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        attendancedata: {
          ...state.attendancedata,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },

    getUserAttendanceById: (state) => {
      return {
        ...state,
        loading: true,
        attendancedata: {},
      };
    },
    userAttendanceByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        attendancedata: {
          ...action.payload,
          id: action.payload.id,
          // user_id: action.payload.user?.id,
          user: {
            id: action.payload.user?.id,
            name:
              action.payload.user?.first_name
          },
          type: action.payload.type,
          date: moment(action.payload.date, "YYYY-MM-DD")
        },
        model: true
      };
    },

    deleteAttendance: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        attendCorreParams: {
          ...state.attendCorreParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAttendCorrection,
  attendCorrectionSuccessful,
  setAttendCorrectionParams,
  SelectChangeValue,
  isModelVisible,
  deleteAttendance,
  attendanceAdd,
  attendanceAddSuccessfully,
  InputChangeValue,
  getUserAttendanceById,
  userAttendanceByIdSuccessful,
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
  checkFilterStatus,
  apiError,
} = attendCorrectionSlice.actions;

export default attendCorrectionSlice.reducer;
