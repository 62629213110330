import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginError: {},
    loginData: {},
    message: null,
    loading: false,
    OTPSend: false,
    resendOTP: false,
    showResentOTP: false,
    pwdData: {},
    // loginResponse: {},
  },
  reducers: {
    cleardata: (state, action) => {
      return {
        ...state,
        loginError: {},
        loginData: {},
        message: null,
        loading: false,
        OTPSend: false,
        pwdData: {},
      };
    },
    checkLogin: (state, action) => {
      return {
        ...state,
        loading: true,
        loginError: {},
      };
    },
    loginUserSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        loginError: {},
        // loginResponse: action.payload,
      };
    },
    getOTPRequest: (state, action) => {
      return {
        ...state,
        loading: true,
        loginError: {},
      };
    },
    OTPRequestSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        loginError: {},
        OTPSend: action.payload,
      };
    },
    logoutUser: (state, action) => {
      return { ...state };
    },
    logoutUserSuccess: (state, action) => {
      return { ...state };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        loginData: {
          ...state.loginData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getResendOTP: (state, action) => {
      return {
        ...state,
        loading: true,
        loginError: {},
      };
    },
    resendOTPSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        loginError: {},
        resendOTP: action.payload,
      };
    },
    showResend: (state, action) => {
      return {
        ...state,
        loading: true,
        showResentOTP: action.payload,
      };
    },

    // User Change Password
    PwdInputChangeValue: (state, action) => {
      return {
        ...state,
        pwdData: {
          ...state.pwdData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    userChangePwd: (state, action) => {
      return {
        ...state,
        loading: true,
        loginError: {},
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, loginError: action.payload };
    },
  },
});
export const {
  cleardata,
  checkLogin,
  loginUserSuccessful,
  getOTPRequest,
  OTPRequestSuccessful,
  logoutUser,
  logoutUserSuccess,
  InputChangeValue,
  getResendOTP,
  resendOTPSuccessful,
  showResend,
  PwdInputChangeValue,
  userChangePwd,
  apiError,
} = loginSlice.actions;

export default loginSlice.reducer;
