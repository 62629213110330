import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import {
  employeeCountSuccessful,
  attendanceCountSuccessful,
  taskListSuccessful,
  taskOverViewSuccessful,
  requestSuccessful,
  attendanceDataSuccessful,
  getAttendanceCount,
  //
  userAttendanceSuccessful,
  userTaskOverViewSuccessful,
  userRequestSuccessful,
  apiError,
  loadingStatus,
} from "./dashboardSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import {
  getList,
  postAdd,
  postDownloadFile,
  getParamsList,
} from "../../helpers/Helper";

function* EmployeeCountGet({ payload }) {
  try {
    const response = yield call(getList, "/users/count/", {});
    if (response.status === 200) {
      yield put(employeeCountSuccessful(response.data));
      yield put(getAttendanceCount());
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AttendanceCountGet({ payload }) {
  try {
    const response = yield call(getList, "/attendance/count/", {});
    yield put(attendanceCountSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* TaskListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/tasks/tasks/", params);
    yield put(taskListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TaskOverView({ payload }) {
  try {
    const response = yield call(getList, "/tasks/total_count/", {});
    yield put(taskOverViewSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* Requests({ payload }) {
  try {
    const response = yield call(getList, "/attendance/total_requests/", {});
    yield put(requestSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AttendanceData({ payload }) {
  try {
    const response = yield call(getList, "/attendance/attendance_count/", {});
    yield put(attendanceDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserAttendance({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/users/attendance_count/",
      {}
    );
    yield put(userAttendanceSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserTaskOverView({ payload }) {
  try {
    const response = yield call(getList, "/tasks/users/total_task_count/", {});
    yield put(userTaskOverViewSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserRequests({ payload }) {
  try {
    const response = yield call(getList, "/attendance/user/requests/", {});
    yield put(userRequestSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetEmployeeCount() {
  yield takeEvery("dashboard/getEmployeeCount", EmployeeCountGet);
}
export function* watchGetAttendanceCount() {
  yield takeEvery("dashboard/getAttendanceCount", AttendanceCountGet);
}
export function* watchGetTaskList() {
  yield takeEvery("dashboard/getTaskList", TaskListGet);
}
export function* watchGetTaskOverView() {
  yield takeEvery("dashboard/getTaskOverView", TaskOverView);
}
export function* watchGetRequests() {
  yield takeEvery("dashboard/getRequests", Requests);
}
export function* watchGetAttendanceData() {
  yield takeEvery("dashboard/getAttendanceData", AttendanceData);
}
export function* watchGetUserAttendance() {
  yield takeEvery("dashboard/getUserAttendance", UserAttendance);
}
//
export function* watchGetUserTaskOverView() {
  yield takeEvery("dashboard/getUserTaskOverView", UserTaskOverView);
}
export function* watchGetUserRequests() {
  yield takeEvery("dashboard/getUserRequests", UserRequests);
}
function* dashboardSaga() {
  yield all([
    fork(watchGetEmployeeCount),
    fork(watchGetAttendanceCount),
    fork(watchGetTaskList),
    fork(watchGetTaskOverView),
    fork(watchGetRequests),
    fork(watchGetAttendanceData),
    fork(watchGetUserAttendance),
    fork(watchGetUserTaskOverView),
    fork(watchGetUserRequests),
  ]);
}

export default dashboardSaga;
