import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/mobilelogin",
    component: lazy(() =>
      import("../../view/pages/authentication/login/mobilenumber-login")
    ),
    layout: "FullLayout",
  },
  {
    path: "/login",
    component: lazy(() =>
      import("../../view/pages/authentication/login/username-login")
    ),
    layout: "FullLayout",
  },
  {
    path: "/change_Password",
    component: lazy(() =>
      import("../../view/pages/authentication/login/userChng-Pwd")
    ),
    layout: "FullLayout",
  },
  // Masters DEC Pages
  {
    path: "/Master",
    component: lazy(() => import("../../view/pages/MastersDashboard")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/departments",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/departments")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/designation",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/designation")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/roles",
    component: lazy(() => import("../../view/pages/MastersDashboard/roles")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/organization",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/organizations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/locations",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/Locations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/City",
    component: lazy(() => import("../../view/pages/MastersDashboard/city")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/state",
    component: lazy(() => import("../../view/pages/MastersDashboard/state")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/leavereasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/leavereasons")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/late_reasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/lateReason")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/onsite_location",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/onSiteLocation")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/shifts",
    component: lazy(() => import("../../view/pages/MastersDashboard/Shifts")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/taskStatus",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/taskStatus")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/templateTask",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/templateTask")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/taskTemplateCreation",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/taskTemplateCreation")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/addtaskTemplate/:id",
    component: lazy(() =>
      import(
        "../../view/pages/MastersDashboard/taskTemplateCreation/addTaskTemplateCreation"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/addprofiletemplate/:id",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/roles/addRoleProfile")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/leaveDefinition",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/leaveDefinition")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/holiday",
    component: lazy(() => import("../../view/pages/MastersDashboard/holiday")),
    layout: "VerticalLayout",
  },

  // User Profile Pages
  {
    path: "/pages/settings/",
    component: lazy(() => import("../../view/pages/settings")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/settings/user-details/:id",
    component: lazy(() =>
      import("../../view/pages/settings/ManageUsers/userDetails")
    ),
    layout: "VerticalLayout",
  },

  // SideBar Pages

  {
    path: "/pages/attendance",
    component: lazy(() => import("../../view/pages/Sidemenu/Attendance")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/lateRequest",
    component: lazy(() => import("../../view/pages/Sidemenu/LateRequests")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/late",
    component: lazy(() => import("../../view/pages/Sidemenu/Late")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/leave",
    component: lazy(() => import("../../view/pages/Sidemenu/Leave")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/onSite",
    component: lazy(() => import("../../view/pages/Sidemenu/OnSite")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/task_management",
    component: lazy(() => import("../../view/pages/Sidemenu/TaskManagement")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/monthlyAttendance",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/MonthlyAttendance")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/monthly-performance",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/MonthlyPerformance")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/attendance_correction",
    component: lazy(() =>
      import("../../view/pages/Sidemenu/attendanceCorrection")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/shift_allocation",
    component: lazy(() => import("../../view/pages/Sidemenu/ShiftAllocation")),
    layout: "VerticalLayout",
  },
  // errors
  {
    path: "/pages/error-page-404",
    component: lazy(() => import("../../view/pages/error/404/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-403",
    component: lazy(() => import("../../view/pages/error/403/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-500",
    component: lazy(() => import("../../view/pages/error/500/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-502",
    component: lazy(() => import("../../view/pages/error/502/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-503",
    component: lazy(() => import("../../view/pages/error/503/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },

  // Profile View
  {
    path: "/pages/userProfile/personel-information",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/activity",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/password-change",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/user-devices",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  // REPORTS
  {
    path: "/pages/reports/",
    component: lazy(() => import("../../view/pages/Reports/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dailymanpowerreport",
    component: lazy(() =>
      import("../../view/pages/Reports/DailyManPowerReport")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/dailyattendancereport",
    component: lazy(() =>
      import("../../view/pages/Reports/EmployeesDailyAttendanceReport")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/lateRequestReport",
    component: lazy(() => import("../../view/pages/Reports/LateRequestReport")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/leaveReport",
    component: lazy(() => import("../../view/pages/Reports/LeaveReport")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/onsiteReport",
    component: lazy(() => import("../../view/pages/Reports/OnsiteReport")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/taskReport",
    component: lazy(() => import("../../view/pages/Reports/TaskReport")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/attendanceReport",
    component: lazy(() =>
      import("../../view/pages/Reports/MonthlyAttendanceReport")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/attendance_Correction_Report",
    component: lazy(() =>
      import("../../view/pages/Reports/AttendanceCorrection")
    ),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/user_shift",
    component: lazy(() => import("../../view/pages/Reports/UserShift")),
    layout: "VerticalLayout",
  },

  {
    path: "/reports/shift_allocation",
    component: lazy(() =>
      import("../../view/pages/Reports/UserShiftAllocation")
    ),
    layout: "VerticalLayout",
  },

  {
    path: "/reports/kpiuser",
    component: lazy(() =>
      import("../../view/pages/Reports/KpiReports/KpiUser")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/reports/kpisummary",
    component: lazy(() =>
      import("../../view/pages/Reports/KpiReports/KpiSummary")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/reports/kpiAllUser",
    component: lazy(() =>
      import("../../view/pages/Reports/KpiReports/KpiAllUser")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/reports/kpiuserwise",
    component: lazy(() =>
      import("../../view/pages/Reports/KpiReports/Kpiuserwise")
    ),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
