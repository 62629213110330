import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const leaveDefinitionSlice = createSlice({
  name: "leaveDefinition",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    leaveDefinitionList: [],
    leaveDefinitionParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    clearLeaveDefinition: (state) =>{
      return {
        ...state,
        loading: true,
        leaveDefinitionList: [],
        rowdata: {},
      }
    },
    getLeaveDefinition: (state) => {
      if (state.leaveDefinitionParams?.page == 1) {
        return {
          ...state,
          loading: true,
          leaveDefinitionList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    LeaveDefinitionSuccessful: (state, action) => {
      var response = action.payload;
      // var list = [];
      // if (state.leaveDefinitionParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.leaveDefinitionList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.leaveDefinitionParams?.page_size
      );
      return {
        ...state,
        // leaveDefinitionList: list,
        leaveDefinitionList: response.results,
        listCount: response.count,
        loading: false,
        leaveDefinitionParams: {
          ...state.leaveDefinitionParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetLeaveDefinitionParams: (state, action) => {
      return {
        ...state,
        leaveDefinitionParams: action.payload,
      };
    },
    leaveDefinitionAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getLeaveDefinitionByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getLeaveDefinitionByIDSuccessful: (state, action) => {
      var from_date = moment(action.payload.from_date).format("YYYY")
      var to_date =  moment(action.payload.to_date).format("YYYY")
      var year = from_date + "-" + to_date
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          // year: moment(action.payload?.year, "YYYY"),
          year: year,
          organization_id: action.payload.organization?.id,
          location_id: action.payload.location?.id,
        },
      };
    },
    leaveDefinitionDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
   
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getLeaveDefinition,
  LeaveDefinitionSuccessful,
  SetLeaveDefinitionParams,
  isModelVisible,
  leaveDefinitionAdd,
  InputChangeValue,
  // taskStatusAddSuccessful,
  leaveDefinitionDelete,
  apiError,
  getLeaveDefinitionByID,
  getLeaveDefinitionByIDSuccessful,
  clearLeaveDefinition,
} = leaveDefinitionSlice.actions;

export default leaveDefinitionSlice.reducer;
