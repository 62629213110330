import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  getUsers,
  usersListSuccessful,
  InputChangeValue,
  isPasswordModel,
  employeeUserbyIDSuccessful,
  shiftAdd,
  shiftByIDSuccessful,
  getShiftByID,
  // Wheelsmart Code
  userMiniZonesListSuccessful,
  userMiniDivisionsListSuccessful,
  userMiniClustersListSuccessful,
  userMiniCountersListSuccessful,
  getUserMiniDivisionsList,
  getUserMiniClustersList,
  getUserMiniCountersList,
  adminUserLoginSuccessful,
  adminUserDeviceListSuccessful,
  adminUserActivityListSuccessful,
  getAdminUserLogin,
  getAdminUserDeviceList,
  getAdminUserActivityList,
} from "./manageUserSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
const getManageUserState = (state) => state.manageUsers;

function* getUserList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/list/", params);
    yield put(usersListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddUser({ payload }) {
  try {
    if (
      payload.userObj.id == 0 ||
      payload.userObj.id == null ||
      payload.userObj.id == undefined
    ) {
      const response = yield call(postAdd, "/users/create/", payload.userObj);

      if (response.status == 201) {
        // yield put(
        //   shiftAdd({
        //     shift_id: payload.userObj.shift_id,
        //     userId: response.data?.id,
        //     history: payload.history,
        //     shiftAllocationId: payload.shiftAllocationId,
        //     type: "add"
        //   })
        // );
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                User Added Successfully
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/settings/manage-users");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add User. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/users/" + payload.userObj.id,
        payload.userObj
      );

      if (response.status == 200) {
        // yield put(
        //   shiftAdd({
        //     shift_id: payload.userObj.shift_id,
        //     userId: response.data.id,
        //     history: payload.history,
        //     shiftAllocationId: payload.shiftAllocationId,
        //     type: "update"
        //   })
        // );
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                User Updated Successfully
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/settings/manage-users");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit User. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddShift({ payload }) {
  try {
    if (payload.shiftAllocationId == 0 || payload.shiftAllocationId == null) {

      const response = yield call(
        postAdd,
        "/attendance/shift_allocation/",
        {
          user_id: payload.userId,
          shift_id: payload.shift_id,
          // shiftAllocationId:  payload
        }
      );
      if (response.status === 201) {
        if (payload.type === "add") {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your User Added Successfully.!
                </p>
              </div>
            ),
          });
        } else {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your User Edit Successfully.!
                </p>
              </div>
            ),
          });
        }
        payload.history.push("/pages/settings/manage-users");
      } else {
        if (payload.type === "add") {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to added User. Please try again!
                </p>
              </div>
            ),
          });
        } else {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to edit User. Please try again!
                </p>
              </div>
            ),
          });
        }
      }
    } else {
      const response2 = yield call(
        postEdit,
        "/attendance/shift_allocation/" + payload.shiftAllocationId,
        {
          user_id: payload.userId,
          shift_id: payload.shift_id,
        }
      );
      if (response2.status === 200) {
        if (payload.type === "add") {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your User Added Successfully.!
                </p>
              </div>
            ),
          });
        } else {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your User Edit Successfully.!
                </p>
              </div>
            ),
          });
        }
        payload.history.push("/pages/settings/manage-users");
      } else {
        if (payload.type === "add") {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to added User. Please try again!
                </p>
              </div>
            ),
          });
        } else {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to edit User. Please try again!
                </p>
              </div>
            ),
          });
        }
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeletemployeeUser({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/employees/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Employee User has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Employee User has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(apiError(error));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
  }
}

function* checkUserEmail({ payload }) {
  try {
    yield call(postAdd, "/users/useremail/validation/", payload);
  } catch (error) {
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "email", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* checkUserName({ payload }) {
  try {
    yield call(postAdd, "/users/username/validation/", payload);
  } catch (error) {
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "username", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.username[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* UserActive({ payload }) {
  try {
    const response = yield call(postAdd, "/users/useractive/" + payload, {});
    if (response.status == 200) {
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    }
  } catch (error) {
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "username", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.phone}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}
function* UserInActive({ payload }) {
  try {
    const response = yield call(postAdd, "/users/userinactive/" + payload, {});
    if (response.status == 200) {
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    }
  } catch (error) {
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "username", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.phone}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}
function* UserUpdatePassword({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/users/updatepassword/" + payload.id,
      payload
    );

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              User Password Update Successfully.!
            </p>
          </div>
        ),
      });
      yield put(isPasswordModel(false));
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update User Password. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (errors) {
    yield put(apiError(errors));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
  }
}

function* employeeUserbyID({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/" + payload, {});
    yield put(employeeUserbyIDSuccessful(response));
    // yield put(getShiftByID(response.id));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* ShiftByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/shift_allocation/user/" + payload,
      {}
    );
    yield put(shiftByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Wheelsmart Code
function* getUserZones({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/zones/", params);
    const manageUserState = yield select(getManageUserState);
    var zonesList = response.map((zone) => {
      if (manageUserState.userSelectedZonesList.indexOf(zone.id) !== -1) {
        zone.zone_checked = true;
      } else {
        zone.zone_checked = false;
      }
      return zone;
    });
    yield put(userMiniZonesListSuccessful(zonesList));
    const configHome = zonesList
      .filter((z) => z.zone_checked)
      .map((zone) => {
        return put(getUserMiniDivisionsList({ zone: zone.id }));
      });
    yield all(configHome);
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getUseriDivisions({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/divisions/",
      params
    );
    const manageUserState = yield select(getManageUserState);

    var divisionsList = response.map((division) => {
      if (
        manageUserState.userSelectedDivisionList.indexOf(division.id) !== -1
      ) {
        division.division_checked = true;
      } else {
        division.division_checked = false;
      }
      division.zone_id = payload.zone;
      return division;
    });

    yield put(
      userMiniDivisionsListSuccessful({ divisionsList, zoneID: payload.zone })
    );
    const configHome = divisionsList
      .filter((d) => d.division_checked)
      .map((division) => {
        return put(
          getUserMiniClustersList({
            division: division.id,
            zoneID: payload.zone,
          })
        );
      });
    yield all(configHome);
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getUseriClusters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages" && k !== "zoneID") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/clusters/",
      params
    );
    const manageUserState = yield select(getManageUserState);
    var clusterList = response.map((cluster) => {
      if (manageUserState.userSelectedClusterList.indexOf(cluster.id) !== -1) {
        cluster.cluster_checked = true;
      } else {
        cluster.cluster_checked = false;
      }
      cluster.division_id = payload.division;
      return cluster;
    });
    yield put(
      userMiniClustersListSuccessful({
        clusterList,
        divisionID: payload.division,
        zoneID: payload.zoneID,
      })
    );
    const configHome = clusterList
      .filter((c) => c.cluster_checked)
      .map((cluster) => {
        return put(
          getUserMiniCountersList({
            cluster: cluster.id,
            zoneID: payload.zoneID,
            divisionID: payload.division,
          })
        );
      });
    yield all(configHome);
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getUseriCounters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (
          payload[k] !== "" &&
          k !== "no_of_pages" &&
          k !== "zoneID" &&
          k !== "divisionID"
        ) {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/counters/all", params);
    const manageUserState = yield select(getManageUserState);
    var counterList = response.map((counter) => {
      if (manageUserState.userSelectedCounterList.indexOf(counter.id) !== -1) {
        counter.counter_checked = true;
      } else {
        counter.counter_checked = false;
      }
      counter.cluster_id = payload.cluster;
      return counter;
    });
    yield put(
      userMiniCountersListSuccessful({
        counterList,
        clusterID: payload.cluster,
        zoneID: payload.zoneID,
        divisionID: payload.divisionID,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* checkUserMobile({ payload }) {
  try {
    yield call(postAdd, "/users/userphone/validation/", payload);
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "phone", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* AdminUserLogin({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages" && k !== "id") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/devicelog/user/" + payload.id,
      params
    );
    yield put(adminUserLoginSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AdminUserDeviceList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages" && k !== "id") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/userdevices/user/" + payload.id,
      params
    );
    yield put(adminUserDeviceListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AdminUserActivityList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages" && k !== "id") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/system/activitylog/user/" + payload.id,
      params
    );
    yield put(adminUserActivityListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchgetUsers() {
  yield takeEvery("manageUser/getUsers", getUserList);
}
export function* watchUserAdd() {
  yield takeEvery("manageUser/userAdd", AddUser);
}
export function* watchShiftAdd() {
  yield takeEvery("manageUser/shiftAdd", AddShift);
}
export function* watchEmployeeUserDelete() {
  yield takeEvery("manageUser/employeeUserDelete", DeletemployeeUser);
}
export function* watchUserMobileValidation() {
  yield takeEvery("manageUser/userMobileValidation", checkUserMobile);
}
export function* watchUserEmailValidation() {
  yield takeEvery("manageUser/userEmailValidation", checkUserEmail);
}
export function* watchUserNameValidation() {
  yield takeEvery("manageUser/userNameValidation", checkUserName);
}
export function* watchuserActive() {
  yield takeEvery("manageUser/userActive", UserActive);
}
export function* watchuserInActive() {
  yield takeEvery("manageUser/userInActive", UserInActive);
}
export function* watchuserUpdatePassword() {
  yield takeEvery("manageUser/updatePassword", UserUpdatePassword);
}
export function* watchGetEmployeeUserbyID() {
  yield takeEvery("manageUser/getEmployeeUserbyID", employeeUserbyID);
}
export function* watchGetShiftByID() {
  yield takeEvery("manageUser/getShiftByID", ShiftByID);
}

// Wheelsmart Code
export function* watchGetUserMiniZonesList() {
  yield takeEvery("manageUser/getUserMiniZonesList", getUserZones);
}
export function* watchGetUserMiniDivisionList() {
  yield takeEvery("manageUser/getUserMiniDivisionsList", getUseriDivisions);
}
export function* watchGetUserMiniClustersList() {
  yield takeEvery("manageUser/getUserMiniClustersList", getUseriClusters);
}
export function* watchGetUserMiniCountersList() {
  yield takeEvery("manageUser/getUserMiniCountersList", getUseriCounters);
}
export function* watchGetAdminUserLogin() {
  yield takeEvery("manageUser/getAdminUserLogin", AdminUserLogin);
}
export function* watchGetAdminUserDeviceList() {
  yield takeEvery("manageUser/getAdminUserDeviceList", AdminUserDeviceList);
}
export function* watchGetAdminUserActivityList() {
  yield takeEvery("manageUser/getAdminUserActivityList", AdminUserActivityList);
}
function* ManageUsersSaga() {
  yield all([
    fork(watchgetUsers),
    fork(watchUserAdd),
    fork(watchShiftAdd),
    fork(watchUserMobileValidation),
    fork(watchUserEmailValidation),
    fork(watchUserNameValidation),
    fork(watchuserActive),
    fork(watchuserInActive),
    fork(watchuserUpdatePassword),
    fork(watchGetEmployeeUserbyID),
    fork(watchGetShiftByID),

    // Wheelsmart Code
    fork(watchEmployeeUserDelete),
    fork(watchGetUserMiniZonesList),
    fork(watchGetUserMiniDivisionList),
    fork(watchGetUserMiniClustersList),
    fork(watchGetUserMiniCountersList),

    fork(watchGetAdminUserLogin),
    fork(watchGetAdminUserDeviceList),
    fork(watchGetAdminUserActivityList),
  ]);
}

export default ManageUsersSaga;
