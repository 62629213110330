import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    locationList: [],
    model: false,
    viewModel: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    customerViewData: {},
    listCount: 0,
    searchPersonId: 0,
    locationParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    filterStatus: false,
  },
  reducers: {
    getLocation: (state, action) => {
      if (state.locationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          locationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    locationListSuccessful: (state, action) => {
      var response = action.payload;
      // var list = [];
      // if (state.locationParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.locationList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.locationParams?.page_size
      );
      return {
        ...state,
        locationList: response.results,
        listCount: response.count,
        loading: false,
        locationParams: {
          ...state.locationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetLocationParams: (state, action) => {
      return {
        ...state,
        locationParams: action.payload,
      };
    },

    locationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    locationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getLocationByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getLocationByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          state_id: action.payload.state.id,
          city_id: action.payload.city.id,
        },
      };
    },


    locationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if(action.payload.key == "state_id"){
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            city_id: null
          },
        };
      }else{
     return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
      }}
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      }
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      if(action.payload.key == "state"){
        return {
          ...state,
          locationParams: {
            ...state.locationParams,
            [action.payload.key]: action.payload.value,
            city: null
          },
        };
      }else{
        return {
          ...state,
          locationParams: {
            ...state.locationParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    clearLocation: (state, action) => {
      return{
        ...state,
        loading: true,
        locationList: [],
        locationParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        filterStatus: false
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLocation,
  locationListSuccessful,
  SetLocationParams,
  locationAdd,
  locationAddSuccessful,
  getLocationByID,
  getLocationByIDSuccessful,
  locationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  checkFilterStatus,
  clearLocation,
} = locationSlice.actions;

export default locationSlice.reducer;
