import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


var firebaseConfig = {
  // new
  apiKey: "AIzaSyD6FZqY5Leez6ElJkmPs_aWsqOxrHgxgW8",
  authDomain: "dec-infra.firebaseapp.com",
  projectId: "dec-infra",
  storageBucket: "dec-infra.appspot.com",
  messagingSenderId: "1062617728075",
  appId: "1:1062617728075:web:1d9cc76d34fe8c3cd9f9ce",
  measurementId: "G-XBQ4WJRZCT"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  
    // return getToken(messaging, { vapidKey: `BPdgIaW99EAZrGBlljQGwAyoC2WKcNgaHSm3sB_ZdXGL_ucOz4ZH_C_hfSfKRLakN6fIvW6aTMRH_An3qpdivGo` })
  return getToken(messaging, { vapidKey: `BAn32Y8X85CedrYJqAQ9JFr-9kaJHLH5rPdEiuBpKTG4mpTGiVx6HYDSTzvKT7bFeAUgN2EAeS0OUft1gKet01s` })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        console.log("currentToken----------", currentToken);
        localStorage.setItem("currentToken", currentToken)
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        requestPermission();
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};


function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
          console.log('Notification permission granted.');
          getToken();
      } else {
          console.log('Unable to get permission to notify.');
      }
  });
}

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', async () => {
//       const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
//           updateViaCache: 'none'
//       });
//       messaging.useServiceWorker(registration);
//   });
// }

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  