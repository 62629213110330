import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  taskTemplatesListSuccessful,
  getTaskTemplatesList,
  getTaskTemplatesByIDSuccessful,

  isModelVisible,

  // Template Task Master
  templateTaskListSuccessful,
} from "./taskTemplateCreationSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { index } from "mathjs";


function* TaskTemplatesListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }

  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] != "" && k != null && k != "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }
  try {
    const response = yield call(getParamsList, "/tasks/templates/", params);
    yield put(taskTemplatesListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* TaskTemplatesByID({ payload }) {
  try {
    const response = yield call(getList, "/tasks/templates/" + payload, {});
    yield put(getTaskTemplatesByIDSuccessful(response.data));
    
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TaskTemplateAdd({ payload }) {
  try {
    if (payload.templateObj.id == null || payload.templateObj.id == undefined) {
      const response = yield call(postAdd, "/tasks/templates/", payload.templateObj );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                   Task Template Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getTaskTemplatesList({ page: 1, search: "", page_size: 10 }));
        // yield put(isModelVisible(false));
        payload.history.push("/Masters/taskTemplateCreation");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Task Template . Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(templateTaskAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/tasks/templates/" + payload.templateObj.id,
        payload.templateObj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Task Template  Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getTaskTemplatesList({ page: 1, search: "", page_size: 10 }));
        // yield put(isModelVisible(false));
        payload.history.push("/Masters/taskTemplateCreation");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Task Template. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(templateTaskAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteTaskTemplate({ payload }) {
  try {
    const response = yield call(postDelete, "/tasks/templates/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Task Template has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getTaskTemplatesList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry!   Task Template has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


// Template Task Master


function* TemplateTaskGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/tasks/template_tasks/", params);
    yield put(templateTaskListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}












function* watchGetTaskTemplatesList() {
  yield takeEvery("taskTemplateCreation/getTaskTemplatesList", TaskTemplatesListGet);
}
function* watchGetTaskTemplatesByID() {
  yield takeEvery("taskTemplateCreation/getTaskTemplatesByID", TaskTemplatesByID);
}
function* watchGetTemplateTask() {
  yield takeEvery("taskTemplateCreation/getTemplateTask", TemplateTaskGet);
}
function* watchAddTaskTemplate() {
  yield takeEvery("taskTemplateCreation/addTaskTemplate", TaskTemplateAdd);
}
function* watchTaskTemplateDelete() {
  yield takeEvery("taskTemplateCreation/taskTemplateDelete", DeleteTaskTemplate);
}


function* TaskTemplateCreationSaga() {
  yield all([
    fork(watchGetTaskTemplatesList),
    fork(watchGetTaskTemplatesByID),
    fork(watchGetTemplateTask),
    fork(watchAddTaskTemplate),
    fork(watchTaskTemplateDelete),
  ]);
}

export default TaskTemplateCreationSaga;
