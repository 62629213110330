import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getShiftList,
  shiftListSuccessful,
  getShiftByIdSuccessful,
  isLeaveAddModel,
  LeaveApproveCheckSuccessful,
  LeaveApproveSuccessful,
  leaveBalanceSuccessful,
  userByIdLeaveBalSuccessful,
  getUserByIdLeaveBal,
  isLeaveAddAprrovedModel,
  LeaveViewApproveSuccessful,
  shiftRequestAdd,
  getShiftById
} from "./shiftAllocationSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* ShiftListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "leave_start_date" || k == "leave_end_date") {
            params["date_multi_range"] =
              moment(data["leave_start_date"]).format("YYYY-MM-DD") +
              "," +
              moment(data["leave_end_date"]).format("YYYY-MM-DD");
            // } else if (k == "end_date") {
            //   params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }

  try {
    const response = yield call(getParamsList, "/attendance/shift_allocation/", params);
    yield put(shiftListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* LeaveApproveCheck({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/leave/check_approve/" + payload.id,
      {}
    );
    if (response.status === 204) {
      yield put(LeaveApproveCheckSuccessful(true));
    } else {
      yield put(LeaveApproveCheckSuccessful(false));
    }
    // yield put(LeaveApproveCheckSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
    yield put(LeaveApproveCheckSuccessful(false));
  }
}

function* ShiftById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/shift_allocation/" + payload.row.id,
      {}
    );
    yield put(
      getShiftByIdSuccessful({ response: response.data, type: payload.type })
    );
    yield put(getUserByIdLeaveBal(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddShiftRequest({ payload }) {
  let shiftParams = yield select((state) => state.shiftAllocation.shiftParams);
  console.log(payload);
  try {
    if (
      !payload.obj.id
    ) {
      const response = yield call(postAdd, "/attendance/shift_allocation/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Shift Allocation Added Successfully.!
              </p>
            </div>
          ),
        });
        // payload.history.push("/pages/leave");
        yield put(getShiftList({ page: 1, search: "", page_size: 10 }));
        yield put(isLeaveAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Shift Allocation. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/attendance/shift_allocation/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Shift Allocation Updated Successfully.!
              </p>
            </div>
          ),
        });
        // payload.history.push("/pages/leave");
        yield put(getShiftList(shiftParams));
        yield put(isLeaveAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Update Shift Allocation. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // yield put(addPurchaseSuccessful(null));
  } catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}

function* ShiftDelete({ payload }) {
  let shiftParams = yield select((state) => state.shiftAllocation.shiftParams);
  try {
    const response = yield call(postDelete, "/attendance/shift_allocation/" + payload.id);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Shift Allocation has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getShiftList(shiftParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Shift Allocation has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* LeaveBalance({ payload }) {
  try {
    const response = yield call(getList, "/attendance/leave_balance/", {});
    yield put(leaveBalanceSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserByIdLeaveBal({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/attendance/leave_balance/" + payload.user.id + "/",
      {}
    );
    yield put(userByIdLeaveBalSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* fetchAllData(ids, approveObj) {
  var successCount = 0;
  var failedCount = 0;
  for (const obj of ids) {
    try {
      const result = yield call(postEdit, `/attendance/leave/approve/${obj.id}`, approveObj);

      if (result.status === 200) {
        successCount++;
      } else {
        failedCount++;
      }
    } catch (error) {
      failedCount++;
    }
  }
  return { successCount, failedCount };
}
function* LeaveApprovePost({ payload }) {
  let shiftParams = yield select((state) => state.shiftAllocation.shiftParams);
  try {
    const { id, approveObj } = payload;
    const { successCount, failedCount } = yield call(fetchAllData, id, approveObj);
    if (approveObj.leave_status == 2) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {successCount} Shift Allocations  Approved Successfully. & {failedCount} Shift Allocations Failed to Approve
            </p>
          </div>
        ),
      });
    } else if (approveObj.leave_status == 3) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {successCount} Shift Allocations  Rejected Successfully. & {failedCount} Shift Allocations Failed to Reject
            </p>
          </div>
        ),
      });
    }
    yield put(LeaveApproveSuccessful());
    yield put(getShiftList(shiftParams));

  } catch (error) {
    yield put(apiError(error));
  }
}

// Leave View Approvals


function* LeaveViewApprove({ payload }) {
  let shiftParams = yield select((state) => state.shiftAllocation.shiftParams);
  try {
    const response = yield call(
      postEdit,
      "/attendance/leave/approve/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.leave_status == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Shift Allocation Approved Successfully.!
              </p>
            </div>
          ),
        });
      } else if (payload.approveObj.leave_status == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Shift Allocation has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(LeaveViewApproveSuccessful(response));
      yield put(
        getShiftList(shiftParams)
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Shift Allocation. Please try again!
            </p>
          </div>
        ),
      });
    }

  } catch (error) {
    yield put(apiError(error));
  }
}


function* AddLeaveApproved({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/attendance/admin/leave/",
      payload.obj
    );
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Shift Allocation Approved Added Successfully.!
            </p>
          </div>
        ),
      });
      payload.history.push("/pages/leave");
      yield put(getShiftList({ page: 1, search: "", page_size: 10 }));
      yield put(isLeaveAddAprrovedModel(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Shift Allocation Approved. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}

export function* watchGetLeaveList() {
  yield takeEvery("shiftAllocation/getShiftList", ShiftListGet);
}
export function* watchCheckLeaveApprove() {
  yield takeEvery("shiftAllocation/CheckLeaveApprove", LeaveApproveCheck);
}
export function* watchGetLeaveById() {
  yield takeEvery("shiftAllocation/getShiftById", ShiftById);
}
export function* watchLeaveDelete() {
  yield takeEvery("shiftAllocation/shiftDelete", ShiftDelete);
}
export function* watchLeaveRequestAdd() {
  yield takeEvery("shiftAllocation/shiftRequestAdd", AddShiftRequest);
}
export function* watchLeaveApprove() {
  yield takeEvery("shiftAllocation/LeaveApprove", LeaveApprovePost);
}
export function* watchLeaveViewApprove() {
  yield takeEvery("shiftAllocation/LeaveViewApprove", LeaveViewApprove);
}
export function* watchGetLeaveBalance() {
  yield takeEvery("shiftAllocation/getLeaveBalance", LeaveBalance);
}
export function* watchGetUserByIdLeaveBal() {
  yield takeEvery("shiftAllocation/getUserByIdLeaveBal", UserByIdLeaveBal);
}
export function* watchLeaveApprovedAdd() {
  yield takeEvery("shiftAllocation/leaveApprovedAdd", AddLeaveApproved);
}
function* ShiftAllocationSaga() {
  yield all([
    fork(watchGetLeaveList),
    fork(watchGetLeaveById),
    fork(watchLeaveDelete),
    fork(watchLeaveRequestAdd),
    fork(watchCheckLeaveApprove),
    fork(watchLeaveApprove),
    fork(watchGetLeaveBalance),
    fork(watchGetUserByIdLeaveBal),
    fork(watchLeaveApprovedAdd),
    fork(watchLeaveViewApprove),
  ]);
}

export default ShiftAllocationSaga;
