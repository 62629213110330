import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  ReportListSuccessful,
  loadingStatus,
} from "./reportsSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import { getList, postAdd, postDownloadFile } from "../../helpers/Helper";

function* ReportList({ payload }) {
  console.log("ReportList", payload.ParamsData);
  var data = payload.ParamsData;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  console.log("params", params);

  try {
    if (payload.objData.file_format == 5) {
      const response = yield call(
        postAdd,
        "/reports/generic_export/",
        payload.objData,
        params
      );
      yield put(ReportListSuccessful(response.data));
    } else {
      yield call(
        postDownloadFile,
        "/reports/generic_export/",
        payload.objData,
        params
      );
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Report Generate Successfully.!
            </p>
          </div>
        ),
      });
      yield put(loadingStatus(false));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetReportList() {
  yield takeEvery(
    "reports/getReportList",
    ReportList
  );
}

function* reportsSaga() {
  yield all([fork(watchGetReportList)]);
}

export default reportsSaga;
