import { createSlice } from "@reduxjs/toolkit";

export const monthlyPerformanceSlice = createSlice({
  name: "monthPerformance",
  initialState: {
    loading: false,
    apiError: {},
    performanceData: {},
  },
  reducers: {
    InputChangeValue: (state, action) => {
      return {
        ...state,
        performanceData: {
          ...state.attendanceData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    getPerformance: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getPerformanceSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

export const {
  InputChangeValue,
  getPerformance,
  getPerformanceSuccessful,
  apiError,
} = monthlyPerformanceSlice.actions;

export default monthlyPerformanceSlice.reducer;
