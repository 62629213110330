import { createSlice } from "@reduxjs/toolkit";
import { e } from "mathjs";
import moment from "moment";

export const shiftAllocationSlice = createSlice({
  name: "shiftAllocation",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    rowdata: {},
    shiftViewModel: false,
    shiftAddModel: false,
    shiftList: [],
    shiftData: {},
    shiftParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    CheckLeaveApprove: false,
    RejectMode: false,
    leaveRejectData: {},
    filterStatus: false,
    leaveBalance: 0,
    userLeaveBal: 0,
    NoOfDayOption: [],
    addApprovedModel: false,
    addApprovedData: {},
    selectedRowKeys: [],
    idStatus: {}
  },
  reducers: {
    getShiftList: (state, action) => {
      if (state.shiftParams?.page == 1) {
        return {
          ...state,
          loading: true,
          shiftList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    shiftListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.shiftParams.page_size
      );
      var idStatus = {}
      action.payload.response.results.map((item) => {
        idStatus[item.id] = item.leave_status
      })
      return {
        ...state,
        listCount: action.payload.response.count,
        // leaveList: [
        //   ...state.leaveList,
        //   ...action.payload.response.results,
        // ],
        idStatus: idStatus,
        shiftList: action.payload.response.results,
        loading: false,
        shiftParams: {
          ...state.shiftParams,
          no_of_pages: noofpages,
        },
      };
    },
    setShiftParams: (state, action) => {
      return {
        ...state,
        shiftParams: action.payload,
      };
    },
    shiftDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    CheckLeaveApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveApproveCheckSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        CheckLeaveApprove: action.payload,
      };
    },
    getShiftById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getShiftByIdSuccessful: (state, action) => {

      return {
        ...state,
        loading: false,
        // shiftAddModel: true,
        shiftData: {
          ...action.payload.response,
          id: action.payload.response?.id,

          date: moment(action.payload.response?.date),
          user: {
            id: action.payload.response.user?.id,
            email: action.payload.response.user?.email,
            phone: action.payload.response.user?.phone,
            organization: action.payload.response.user?.organization,
            location: action.payload.response.user?.location,
            name:
              action.payload.response.user?.first_name +
              " " +
              action.payload.response.user?.username,
              username: action.payload.response.user?.username,
            first_name: action.payload.response.user?.first_name,
          },
          shift_id: action.payload.response?.shift?.id,
          
        },
        shiftAddModel: action.payload.type == "update" ? true : false,
        shiftViewModel: action.payload.type == "view" ? true : false,
      };
    },

    shiftRequestAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    isShiftViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          shiftViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          shiftData: {},
          shiftViewModel: action.payload,
        };
      }
    },
    isLeaveAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          loading: false,
          shiftAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          loading: false,
          shiftAddModel: action.payload,
          shiftData: {},
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        shiftParams: {
          ...state.shiftParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    InputChangeValue: (state, action) => {
      console.log(action.payload)
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        leaveRejectData: {},
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        leaveRejectData: {
          ...state.leaveRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearLeaveList: (state, action) => {
      return {
        ...state,
        loading: true,
        shiftList: [],
        shiftParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        filterStatus: false,
        selectedRowKeys: []
      };
    },
    isDrawerVisible: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        drawer: action.payload,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        shiftData: {
          ...state.shiftData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name
          },
        },
      };
    },
    getLeaveBalance: (state, action) => {
      return {
        ...state,
        loading: true,
        leaveBalance: 0,
      }
    },
    leaveBalanceSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        leaveBalance: action.payload.leave_balance.toFixed(3),
      }
    },
    getUserByIdLeaveBal: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userByIdLeaveBalSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        userLeaveBal: action.payload.leave_balance,
      };
    },
    isLeaveAddAprrovedModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          addApprovedModel: action.payload,
        };
      } else {
        return {
          ...state,
          addApprovedModel: action.payload,
          addApprovedData: {},
        };
      }
    },
    ApprovedInputChangeValue: (state, action) => {
      if (action.payload.key == "from_date") {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
            to_date: null,
            no_of_days: null,
          },
        };
      } else if (action.payload.key == "to_date") {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
            no_of_days: action.payload.value == "" ? null : action.payload.value.diff(state.addApprovedData.from_date, "days") + 1,
          },
          NoOfDayOption: action.payload.value == "" ? [] : [action.payload.value.diff(state.addApprovedData.from_date, "days") + 1, action.payload.value.diff(state.addApprovedData.from_date, "days") + 0.5],
        };
      } else {
        return {
          ...state,
          addApprovedData: {
            ...state.addApprovedData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    SelectApprovedChangeValue: (state, action) => {
      return {
        ...state,
        addApprovedData: {
          ...state.addApprovedData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name
          },
        },
      };
    },
    leaveApprovedAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    setSelectedRowKeys: (state, action) => {
      return {
        ...state,
        loading: false,
        selectedRowKeys: action.payload,
      }
    },
    selectRow: (state, action) => {
      console.log("selectRow", action.payload)
      const updatedSelectedRowKeys = [...state.selectedRowKeys];
      if (updatedSelectedRowKeys.includes(state.selectedRowKeys.key)) {
        const index = updatedSelectedRowKeys.indexOf(state.selectedRowKeys.key);
        updatedSelectedRowKeys.splice(index, 1);
      } else {
        updatedSelectedRowKeys.push(state.selectedRowKeys.key);
      }
      return {
        ...state,
        loading: false,
        selectedRowKeys: updatedSelectedRowKeys,
      }
    },


    // Leave List & Grid approvals
    LeaveApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // shiftViewModel: false,
        // RejectMode: false,
        selectedRowKeys: [],
      };
    },
    // Leave view approvals
    LeaveViewApprove: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    LeaveViewApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        shiftViewModel: false,
        RejectMode: false,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getShiftList,
  shiftListSuccessful,
  setShiftParams,
  getShiftById,
  getShiftByIdSuccessful,
  shiftDelete,
  isShiftViewModel,
  isLeaveAddModel,
  FilterInputChangeValue,
  InputChangeValue,
  shiftRequestAdd,
  CheckLeaveApprove,
  LeaveApproveCheckSuccessful,
  LeaveApprove,
  LeaveApproveSuccessful,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  isDrawerVisible,
  clearLeaveList,
  checkFilterStatus,
  SelectChangeValue,
  getLeaveBalance,
  leaveBalanceSuccessful,
  getUserByIdLeaveBal,
  userByIdLeaveBalSuccessful,
  isLeaveAddAprrovedModel,
  ApprovedInputChangeValue,
  SelectApprovedChangeValue,
  leaveApprovedAdd,
  setSelectedRowKeys,
  selectRow,
  LeaveViewApprove,
  LeaveViewApproveSuccessful,
  apiError,
} = shiftAllocationSlice.actions;

export default shiftAllocationSlice.reducer;
