import { createSlice } from "@reduxjs/toolkit";

export const taskStatusSlice = createSlice({
  name: "taskStatus",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    taskStatuslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    taskStatusParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getTaskStatus: (state) => {
      if (state.taskStatusParams?.page == 1) {
        return {
          ...state,
          loading: true,
          taskStatuslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    taskStatusListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.taskStatusParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.taskStatuslist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.taskStatusParams?.page_size
      );
      return {
        ...state,
        taskStatuslist: list,
        listCount: response.count,
        loading: false,
        taskStatusParams: {
          ...state.taskStatusParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetTaskStatusParams: (state, action) => {
      return {
        ...state,
        taskStatusParams: action.payload,
      };
    },
    taskStatusAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    taskStatusAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getTaskStatusByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTaskStatusByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        },
      };
    },
    taskStatusDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getTaskStatus,
  taskStatusListSuccessful,
  SetTaskStatusParams,
  isModelVisible,
  taskStatusAdd,
  taskStatusAddSuccessful,
  taskStatusDelete,
  InputChangeValue,
  apiError,
  getTaskStatusByID,
  getTaskStatusByIDSuccessful,
} = taskStatusSlice.actions;

export default taskStatusSlice.reducer;
