import { takeEvery, fork, put, all, call, race } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  isTaskAddModel,
  taskManagementListSuccessful,
  getTaskByIdSuccessful,
  getTaskManagementList,
  isTaskViewModel,
  getTaskById,
  taskStatusSuccessful,
  taskByCommentListSuccessful,
  getTaskByCommentList,
  taskImageUploadSuccessful,
  getTaskImageUpload,
  taskViewImgUploadSuccessful,
  clearComments,
} from "./taskManagementSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* TaskManagementListGet({ payload }) {
  var data = payload;
  var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] != "" && k != null && k != "no_of_pages") {
  //         if (k == "task_start_date" || k == "task_end_date") {
  //           params["date_multi_range"] =
  //             moment(data["task_start_date"]).format("YYYY-MM-DD") +
  //             "," +
  //             moment(data["task_end_date"]).format("YYYY-MM-DD");
  //           // } else if (k == "end_date") {
  //           //   params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
  //         } else if (k == "start_date") {
  //           params[k] = moment(data[k]).format("YYYY-MM-DD");
  //         } else if (k == "end_date") {
  //           params[k] = moment(data[k]).format("YYYY-MM-DD");
  //         } else {
  //           params[k] = data[k];
  //         }
  //       }
  //     }
  //   }
  // }
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "due_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/tasks/tasks/", params);
    yield put(taskManagementListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TaskById({ payload }) {
  try {
    const response = yield call(getList, "/tasks/tasks/" + payload.row.id, {});
    yield put(
      getTaskByIdSuccessful({ response: response.data, type: payload.type })
    );
    yield put(getTaskByCommentList(response.data.id));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TaskByCommentList({ payload }) {
  try {
    const response = yield call(
      getList,
      "/tasks/task_comments/task/" + payload,
      {}
    );
    yield put(taskByCommentListSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* TaskDelete({ payload }) {
  let taskParams = yield select((state) => state.task.taskParams)
  try {
    const response = yield call(postDelete, "/tasks/tasks/" + payload.id);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Task has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getTaskManagementList(taskParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Task has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* TaskStatusChange({ payload }) {
  let taskParams = yield select((state) => state.task.taskParams)
  try {
    const response = yield call(
      postEdit,
      "/tasks/tasks/task_status_update/" + payload.data.id,
      { task_status_id: payload.task_status_id }
    );
    if (payload.type == "list") {
      yield put(getTaskManagementList(taskParams));
    } else if (payload.type == "view") {
      yield put(getTaskById({ row: payload.data, type: payload.type }));
      yield put(getTaskManagementList(taskParams));
    }
    yield put(isTaskAddModel(false));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* taskStatusGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/task_status/",
      params
    );
    yield put(taskStatusSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* AddTask({ payload }) {
  let taskParams = yield select((state) => state.task.taskParams)
  try {
    if (
      payload.obj.id == 0 ||
      payload.obj.id == undefined ||
      payload.obj.id == null
    ) {
      const response = yield call(postAdd, "/tasks/tasks/", payload.obj);
      if (response.status === 201) {
        const configHome = payload.addTaskListImg.map((image) => {
          return call(postFormData, "/tasks/task_attachments/", {
            file: image.file,
            task_id: response.data?.id
          });
        });
        const response1 = yield all(configHome);

        // const configHome = payload.addTaskListImg.map((image) => {
        //   return call(getTaskImageUpload, {
        //     file: image.file,
        //     task_id: response.data.task?.id,
        //     task_comment_id: response.data.id,
        //   });
        // });
        // yield all(configHome);

        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Task Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/task_management");
        yield put(getTaskManagementList({ page: 1, search: "", page_size: 10 }) );
        yield put(isTaskAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Task. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/tasks/tasks/update/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        // const configHome = payload.addTaskListImg.map((image) => {
        //   return put(
        //     getTaskImageUpload({
        //       file: image.file,
        //       task_id: response.data.id,
        //     })
        //   );
        // });
        // yield all(configHome);
        const configHome = payload.addTaskListImg.map((image) => {
          return put(
            getTaskImageUpload({
              file: image.file,
              task_id: response.data?.id,
              // task_comment_id: response.data.id,
            })
          );
        });
        yield all(configHome);
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Task Edited Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/task_management");
        yield put(
          getTaskManagementList(taskParams)
        );
        yield put(isTaskAddModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Task. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* AddComment({ payload }) {
  try {
    if (
      payload.obj.id == 0 ||
      payload.obj.id == undefined ||
      payload.obj.id == null
    ) {
      var response = yield call(postAdd, "/tasks/task_comments/", payload.obj);
      if (response.status === 201) {
        const configHome = payload.taskCommentImg.map((image) => {
          return call(postFormData, "/tasks/task_attachments/", {
            file: image.file,
            task_id: response.data.task?.id,
            task_comment_id: response.data?.id,
          });
        });
        const response1 = yield all(configHome);
        // const configHome = payload.taskCommentImg.map((image) => {
        //   return put(
        //     getTaskImageUpload({
        //       file: image.file,
        //       // file: {
        //       //   imageType: image.imageType,
        //       // },
        //       task_id: response.data.task?.id,
        //       task_comment_id: response.data.id,
        //     })
        //   );
        // });
        // yield all(configHome);

        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Task Comment Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(clearComments());
        yield put(
          getTaskById({ row: { id: response.data.task?.id }, type: "view" })
        );
        // payload.history.push("/pages/task_management");
        // yield put(getTaskByCommentList(response.data.task?.id));
        // yield put(isTaskViewModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Task Comment. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/tasks/task_comments/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        const configHome = payload.taskCommentImg.map((image) => {
          return put(
            getTaskImageUpload({
              file: image.file,
              // file: {
              //   imageType: image.imageType,
              // },
              task_id: response.data.task?.id,
              task_comment_id: response.data.id,
            })
          );
        });
        yield all(configHome);
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Task Comment Edited Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/task_management");
        yield put(getTaskByCommentList(response.data.task?.id));
        yield put(isTaskViewModel(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Task comment. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteComment({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/tasks/task_comments/" + payload.id
    );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Task Comment has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getTaskByCommentList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Task Comment has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* TaskImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/tasks/task_attachments/", {
      file: payload.file,
      task_id: payload.task_id,
      task_comment_id: payload?.task_comment_id,
    });
    yield put(
      taskImageUploadSuccessful({
        response,
        payload,
        status: "done",
        msg: "File uploaded successfully",
      })
    );
  } catch (error) {
    // yield put(apiError(error));
    // if (error.response.status === 413) {
    yield put(
      taskImageUploadSuccessful({
        response: "",
        payload,
        status: "error",
        msg: error,
      })
    );
    // }else{
    // yield put( taskImageUploadSuccessful({ response, payload, status: 'error', msg: "Unable upload file"}));
    // }
  }
}

function* ViewTaskImgUpload({ payload }) {
  try {
    //TaskImageUpload
    // const [customers, products] = yield all([
    //   call(postFormData, "/tasks/task_attachments/", {
    //     file: payload.file,
    //     task_id: payload.task_id,
    //     task_comment_id: payload?.task_comment_id,
    //   }),
    //   call(fetchProducts),
    // ]);
    // const configHome = payload.taskImg.map((image) => {
    //   return call(
    //     getTaskImageUpload({
    //       file: image.file,
    //       task_id: payload.task_id,
    //     })
    //   );
    // });
    console.log("payload.taskImg ********************* ", payload.taskImg);
    const configHome = payload.taskImg.map((image) => {
      return call(postFormData, "/tasks/task_attachments/", {
        file: image.file,
        task_id: payload.task_id,
        task_comment_id: payload?.task_comment_id,
      });
    });
    const response = yield all(configHome);
    console.log("image,response ********************* ", response);
    yield put(taskViewImgUploadSuccessful(response));
    yield put(getTaskById({ row: { id: payload.task_id }, type: "view" }));
  } catch (error) {
    yield put(apiError(error));
    // // if (error.response.status === 413) {
    //   yield put( taskImageUploadSuccessful({ response: "", payload, status: 'error', msg: error}));
    // // }else{
    //   // yield put( taskImageUploadSuccessful({ response, payload, status: 'error', msg: "Unable upload file"}));
    // // }
  }
}

export function* watchGetTaskManagementList() {
  yield takeEvery("task/getTaskManagementList", TaskManagementListGet);
}
export function* watchGetTaskById() {
  yield takeEvery("task/getTaskById", TaskById);
}
export function* watchTaskAdd() {
  yield takeEvery("task/taskAdd", AddTask);
}
export function* watchTaskDelete() {
  yield takeEvery("task/taskDelete", TaskDelete);
}
export function* watchChangeTaskStatus() {
  yield takeEvery("task/changeTaskStatus", TaskStatusChange);
}
export function* watchGetTaskStatus() {
  yield takeEvery("task/getTaskStatus", taskStatusGet);
}
export function* watchCommentAdd() {
  yield takeEvery("task/commentAdd", AddComment);
}
export function* watchCommentDelete() {
  yield takeEvery("task/commentDelete", DeleteComment);
}
export function* watchGetTaskByCommentList() {
  yield takeEvery("task/getTaskByCommentList", TaskByCommentList);
}

export function* watchGetTaskImageUpload() {
  yield takeEvery("task/getTaskImageUpload", TaskImageUpload);
}
export function* watchTaskViewImgUpload() {
  yield takeEvery("task/taskViewImgUpload", ViewTaskImgUpload);
}
function* TaskManagementSaga() {
  yield all([
    fork(watchGetTaskManagementList),
    fork(watchGetTaskById),
    fork(watchTaskAdd),
    fork(watchTaskDelete),
    fork(watchChangeTaskStatus),
    fork(watchGetTaskStatus),
    fork(watchCommentAdd),
    fork(watchCommentDelete),
    fork(watchGetTaskByCommentList),
    fork(watchGetTaskImageUpload),
    fork(watchTaskViewImgUpload),
  ]);
}

export default TaskManagementSaga;
