import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Router from "./router/Router";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import {sidebarEnable} from "./store/customise/customiseSlice";
import { log } from "mathjs";
export default function App() {
  const customise = useSelector((state) => state.customise);
  useEffect(() => {
    if (!isMobile) {
      sidebarEnable(true);
    }
    return () => {
      sidebarEnable(false);
    };
  }, [])

  return (
    <ConfigProvider direction={customise.direction}>
      <Router />
    </ConfigProvider>
  );
}
