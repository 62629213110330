import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "roles",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    rolesList: [],
    selecTaskTemplate: [],
    model: false,
    error_msg: null,
    rowdata: {},
    rolesParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    addTaskTemplateRow: (state, action) => {
      return {
        ...state,
        loading: false,
        selecTaskTemplate: [
          ...state?.selecTaskTemplate,
          {
            task_template_id: action.payload.id,
            name: action.payload.name,
            weightage: null,
            dodelete: false,
          },
        ],
        model: false,
      };
    },

    removeTaskTemplate: (state, action) => {
      // console.log(state.selecTaskTemplate, "remove_id");
      var selectedTask2 = [];
      if (action.payload.id) {
        selectedTask2 = state.selecTaskTemplate?.map((item) => {
          if (item.task_template_id == action.payload.task_template_id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      } else {
        selectedTask2 = state.selecTaskTemplate.filter((item) => {
          if (
            item.task_template_id == action.payload.task_template_id &&
            item.dodelete == false
          ) {
            return 0;
          } else {
            return item;
          }
        });

        // console.log(selectedTask2, "delete task");
      }
      return {
        ...state,
        selecTaskTemplate: selectedTask2,
      };
    },

    TaskInputChangeValue: (state, action) => {
      // console.log(state.selecTaskTemplate, "onchange");
      var selectedTask = state.selecTaskTemplate.map((item) => {
        if (item?.task_template_id == action.payload.item.task_template_id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });

      return {
        ...state,
        selecTaskTemplate: selectedTask,
      };
    },
    clearRoleData: (state) => {
      return {
        ...state,
        model: false,
        rowdata: {},
        selecTaskTemplate: [],
      };
    },
    getRoles: (state) => {
      if (state.rolesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          rolesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    rolesListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.rolesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.rolesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.rolesParams?.page_size
      );
      return {
        ...state,
        rolesList: list,
        listCount: response.count,
        loading: false,
        rolesParams: {
          ...state.rolesParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetRolesParams: (state, action) => {
      return {
        ...state,
        rolesParams: action.payload,
      };
    },
    roleAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    roleAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
        selecTaskTemplate: [],
      };
    },
    roleDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    roleById: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    roleByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        selecTaskTemplate: action.payload.roletasks?.map(function (elem) {
          return {
            id: elem.id,
            task_template_id: elem.template.id,
            name: elem.template.name,
            weightage: elem.weightage,
            dodelete: elem.is_deleted == 0 ? false : true,
          };
        }),
        rowdata: {
          ...action.payload,
        },
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRoles,
  rolesListSuccessful,
  SetRolesParams,
  roleAdd,
  roleAddSuccessful,
  roleById,
  roleByIdSuccessful,
  roleDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  clearRoleData,
  addTaskTemplateRow,
  removeTaskTemplate,
  TaskInputChangeValue,
} = roleSlice.actions;

export default roleSlice.reducer;
