import { createSlice } from "@reduxjs/toolkit";

export const leavereasonsSlice = createSlice({
  name: "leavereasons",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    leavereasonslist: [],
    model: false,
    error_msg: null,
    listCount: 0,
    rowdata: {},
    leavereasonsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getLeavereasons: (state) => {
      if (state.leavereasonsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          leavereasonslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    leavereasonsListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.leavereasonsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.leavereasonslist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.leavereasonsParams?.page_size
      );
      return {
        ...state,
        leavereasonslist: list,
        listCount: response.count,
        loading: false,
        leavereasonsParams: {
          ...state.leavereasonsParams,
          no_of_pages: noofpages,
        },
      };
    },
    leavereasonsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    leavereasonsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    leaveReasonsById: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    leaveReasonsByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        }
      };
    },
    leavereasonsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetLeavereasonsParams: (state, action) => {
      return {
        ...state,
        leavereasonsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLeavereasons,
  leavereasonsListSuccessful,
  leavereasonsAdd,
  leavereasonsAddSuccessful,
  leavereasonsDelete,
  InputChangeValue,
  leaveReasonsById,
  leaveReasonsByIdSuccessful,
  apiError,
  isModelVisible,
  SetLeavereasonsParams
} = leavereasonsSlice.actions;

export default leavereasonsSlice.reducer;
