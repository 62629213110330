import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

export const manageuserSlice = createSlice({
  name: "manageUser",
  initialState: {
    listCount: 0,
    Userslist: [],
    drawer: false,
    employeeUserdata: {},
    model: false,
    userId: 0,
    passwordModel: false,
    shiftAllocationId: 0,
    // Params
    usersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },

    // Wheelsmart Code
    loginListCount: 0,
    deviceListCount: 0,
    activityListCount: 0,
    showPassword: true,
    activeTab: "1",
    UserDeviceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    profileData: {},
    userMiniZonesList: [],
    userSelectedZonesList: [],
    userSelectedDivisionList: [],
    userSelectedClusterList: [],
    userSelectedCounterList: [],
    userMiniDivisionsList: [],
    selectedZoneID: 0,
    selectedDivisionID: 0,
    selectedClusterID: 0,

    adminUserLoginList: [],
    adminUserDeviceList: [],
    adminUserActivityList: [],
    // usersFilterParams: {
    //   no_of_pages: 0,
    //   page_size: 10,
    //   page: 1,
    //   search: "",
    //   currentSort: "code",
    //   sortOrder: "-",
    // },
  },

  reducers: {
    getUsers: (state, action) => {
      if (action.payload?.page == 1) {
        return {
          ...state,
          loading: true,
          Userslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    usersListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.usersParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        Userslist: action.payload.results,
        loading: false,
        usersParams: {
          ...state.usersParams,
          no_of_pages: noofpages,
        },
      };
    },
    userAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userAddSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    shiftAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    clearProfileData: (state, action) => {
      return {
        ...state,
        loading: false,
        employeeUserdata: {},
      };
    },
    clearUserData: (state, action) => {
      return {
        ...state,
        loading: false,
        employeeUserdata: {},
        Userslist: [],
        usersParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        usersParams: {
          ...state.usersParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    userMobileValidation: (state) => {
      return {
        ...state,
      };
    },
    userEmailValidation: (state) => {
      return {
        ...state,
      };
    },
    userNameValidation: (state) => {
      return {
        ...state,
      };
    },
    userActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userInActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getUserID: (state, action) => {
      return {
        ...state,
        loading: false,
        userId: action.payload,
      };
    },
    setUsersParams: (state, action) => {
      return {
        ...state,
        usersParams: action.payload,
      };
    },
    isPasswordModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          passwordModel: action.payload,
          employeeUserdata: {},
        };
      } else {
        return {
          ...state,
          passwordModel: action.payload,
        };
      }
    },
    updatePassword: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getEmployeeUserbyID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    employeeUserbyIDSuccessful: (state, action) => {
      // var groupsList = [];
      // groupsList = action.payload?.groups.map((groups) => {
      //   return groups.id;
      // });
      return {
        ...state,
        loading: false,
        employeeUserdata: {
          ...action.payload,
          id: action.payload?.id,

          group:
            action.payload.groups.length != 0
              ? action.payload.groups[0]
              : "",
          // group_ids: groupsList,
          department: action.payload?.department,
          designation: action.payload?.designation,
          role_id: action.payload?.role?.id,
          location: action.payload?.location,
          organization: action.payload?.organization,
          dafault_shift_id: action.payload?.dafault_shift?.id,
          reporting_to: {
            id: action.payload?.reporting_to?.id,
            name: action.payload.reporting_to?.first_name + " - " + action.payload.reporting_to?.username,
            first_name: action.payload.reporting_to?.first_name,
          },
          DOB: action.payload.DOB == null ? "" : moment(action.payload.DOB),
          DOJ: action.payload.DOJ == null ? "" : moment(action.payload.DOJ),
          DOR: action.payload.DOR == null ? "" : moment(action.payload.DOR),
          DOE: action.payload.DOE == null ? "" : moment(action.payload.DOE),
          // DOB: moment(action.payload?.DOB),
          // DOJ: moment(action.payload?.DOJ),
          // DOR: moment(action.payload?.DOR),
          // DOE: moment(action.payload?.DOE),
        },
      };
    },
    getShiftByID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    shiftByIDSuccessful: (state, action) => {
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          shift_id: action.payload?.shift?.id,
          shift: action.payload?.shift,
        },
        shiftAllocationId: action.payload.id,
        loading: false,
      };
    },

    // Wheelsmart Code
    getUserMiniZonesList: (state) => {
      return {
        ...state,
        userMiniZonesList: [],
        loading: true,
      };
    },
    userMiniZonesListSuccessful: (state, action) => {
      return {
        ...state,
        userMiniZonesList: action.payload,
        loading: false,
      };
    },
    showDivisions: (state, action) => {
      return {
        ...state,
        selectedZoneID: action.payload,
      };
    },
    getUserMiniDivisionsList: (state, action) => {
      return {
        ...state,
        userMiniDivisionsList: [],
        loading: true,
      };
    },
    userMiniDivisionsListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions = action.payload.divisionsList;
        }
      });
    },
    showClusters: (state, action) => {
      return {
        ...state,
        selectedDivisionID: action.payload,
      };
    },
    getUserMiniClustersList: (state, action) => {
      return {
        ...state,
        userMiniClustersList: [],
        loading: true,
      };
    },
    userMiniClustersListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionID) {
              division.clusters = action.payload.clusterList;
            }
          });
        }
      });
    },
    showCounters: (state, action) => {
      return {
        ...state,
        selectedClusterID: action.payload,
      };
    },
    getUserMiniCountersList: (state, action) => {
      return {
        ...state,
        userMiniCountersList: [],
        loading: true,
      };
    },
    userMiniCountersListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionID) {
              division.clusters.map((cluster) => {
                if (cluster.id == action.payload.clusterID) {
                  cluster.counters = action.payload.counterList;
                }
              });
            }
          });
        }
      });
    },
    checkZonePermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zonePermission_id) {
          zone.zone_checked = !zone.zone_checked;
          if (zone.zone_checked == true) {
            // zone.divisions.map((division) => {
            //   division.clusters?.map((cluster) => {
            //     cluster.counters?.map((counter) => {
            //       counter.counter_checked = true;
            //     });
            //     cluster.cluster_checked = true;
            //   });
            //   division.division_checked = true;
            // });
          } else {
            zone.divisions.map((division) => {
              division.clusters?.map((cluster) => {
                cluster.counters?.map((counter) => {
                  counter.counter_checked = false;
                });
                cluster.cluster_checked = false;
              });
              division.division_checked = false;
            });
          }
        }
      });
    },
    checkDivisionPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionPermission_id) {
              division.division_checked = !division.division_checked;
              if (division.division_checked != true) {
                division.clusters?.map((cluster) => {
                  cluster.counters?.map((counter) => {
                    counter.counter_checked = false;
                  });

                  cluster.cluster_checked = false;
                });
              }
            }
          });
        }
      });
    },
    checkClusterPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (
              division.id == action.payload.divisionId &&
              division.clusters !== null
            ) {
              division.clusters.map((cluster) => {
                if (cluster.id == action.payload.clusterPermission_id) {
                  cluster.cluster_checked = !cluster.cluster_checked;
                  if (cluster.cluster_checked != true) {
                    cluster.counters.map((counter) => {
                      counter.counter_checked = false;
                    });
                  }
                }
              });
            }
          });
        }
      });
    },
    checkCounterPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (
              division.id == action.payload.divisionId &&
              division.clusters !== null
            ) {
              division.clusters.map((cluster) => {
                if (
                  cluster.id == action.payload.clusterId &&
                  cluster.counters !== null
                ) {
                  cluster.counters.map((counter) => {
                    if (counter.id == action.payload.counterPermission_id) {
                      counter.counter_checked = !counter.counter_checked;
                    }
                  });
                }
              });
            }
          });
        }
      });
    },

    profileDataById: (state, action) => {
      return {
        ...state,
        loading: true,
        profileData: action.payload,
      };
    },

   
    SelecteChangeValue: (state, action) => {
      // return {
      //   ...state,
      //   employeeUserdata: {
      //     ...state.employeeUserdata,
      //     [action.payload.key]: action.payload.label,
      //     [action.payload.key2]: action.payload.value,
      //   },
      // };
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name
          },
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          employeeUserdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    employeeUserDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getAdminUserLogin: (state) => {
      return {
        ...state,
        loading: true,
        adminUserLoginList: [],
      };
    },
    adminUserLoginSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        loginListCount: action.payload.response.count,
        // adminUserActivityLogList: action.payload.response.results,
        adminUserLoginList: [
          ...state.adminUserLoginList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },

    getAdminUserDeviceList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserDeviceList: [],
      };
    },
    adminUserDeviceListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        deviceListCount: action.payload.response.count,
        // adminUserDeviceList: action.payload.response.results,
        adminUserDeviceList: [
          ...state.adminUserDeviceList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },

    getAdminUserActivityList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserActivityList: [],
      };
    },
    adminUserActivityListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        activityListCount: action.payload.response.count,
        adminUserActivityList: [
          ...state.adminUserActivityList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUsersDeviceParams: (state, action) => {
      return {
        ...state,
        UserDeviceParams: action.payload,
      };
    },
    // clearExpanddata: (state) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     adminUserDeviceList: [],
    //     adminUserActivityLogList: [],
    //   };
    // },
    userDeviceActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },
    clearAdminUserLogin: (state) => {
      return {
        ...state,
        loading: true,
        adminUserLoginList: [],
      };
    },
    clearAdminUserDeviceList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserDeviceList: [],
      };
    },
    clearAdminUserActivityList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserActivityList: [],
      };
    },

    // setUsersFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     usersFilterParams: action.payload,
    //   };
    // },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUsers,
  usersListSuccessful,
  setUsersParams,
  employeeUserDelete,
  userMobileValidation,
  userEmailValidation,
  userNameValidation,
  userActive,
  userInActive,
  isDrawerVisible,
  FilterInputChangeValue,
  getUserID,
  InputChangeValue,
  userAdd,
  userAddSuccessful,
  shiftAdd,
  clearProfileData,
  clearUserData,
  profileDataById,
  isPasswordModel,
  updatePassword,
  getEmployeeUserbyID,
  employeeUserbyIDSuccessful,
  getShiftByID,
  shiftByIDSuccessful,

  // Wheelsmart Code
  getUserMiniZonesList,
  userMiniZonesListSuccessful,
  getUserMiniDivisionsList,
  userMiniDivisionsListSuccessful,
  getUserMiniClustersList,
  userMiniClustersListSuccessful,
  getUserMiniCountersList,
  userMiniCountersListSuccessful,
  showDivisions,
  showClusters,
  showCounters,
  checkZonePermission,
  checkDivisionPermission,
  checkClusterPermission,
  checkCounterPermission,

  SelecteChangeValue,
  isModelVisible,

  getAdminUserLogin,
  adminUserLoginSuccessful,
  getAdminUserDeviceList,
  adminUserDeviceListSuccessful,
  setUsersDeviceParams,
  // clearExpanddata,
  userDeviceActiveTab,
  getAdminUserActivityList,
  adminUserActivityListSuccessful,
  clearAdminUserLogin,
  clearAdminUserDeviceList,
  clearAdminUserActivityList,

  // setUsersFilterParams,
  apiError,
} = manageuserSlice.actions;

export default manageuserSlice.reducer;
