import { all } from "redux-saga/effects";

// login
import loginSaga from "./auth/login/saga";

// notification
import notificationSaga from "./notifications/saga";

//userProfile
import userProfileSaga from "./userProfile/saga";

// Settings
import ManageUsersSaga from "./settings/ManageUsers/saga";
import ManageProfileSaga from "./settings/ManageProfile/saga";
import ManagersSaga from "./settings/Managers/saga";
import LocationManagerSaga from "./settings/LocationManager/saga";
import ApprovalSaga from "./settings/Approval/saga";
import ImportExportSaga from "./settings/ImportExport/saga";
import BackupDatabaseSaga from "./settings/BackupDatabse/saga";
import ManageCompanySaga from "./settings/ManageCompany/saga";
import AppSettingsSaga from "./settings/AppSettings/saga";

//mini
import miniSaga from "./mini/saga";

// Masters
import DepartmentSaga from "./Masters/Departments/saga";
import DesignationSaga from "./Masters/Designation/saga";
import RoleSaga from "./Masters/Roles/saga";
import OrganizationSaga from "./Masters/Organizations/saga";
import LocationSaga from "./Masters/Location/saga";
import LeavereasonsSaga from "./Masters/Leavereasons/saga";
import StateSaga from "./Masters/State/saga";
import CitySaga from "./Masters/City/saga";
import LateReasonsSaga from "./Masters/lateReason/saga";
import OnSiteLocationSaga from "./Masters/OnSiteLocation/saga";
import ShiftSaga from "./Masters/Shifts/saga";
import TaskStatus from "./Masters/TaskStatus/saga";
import TemplateTaskSaga from "./Masters/TemplateTask/saga";
import TaskTemplateCreationSaga from "./Masters/TaskTemplateCreation/saga";
import LeaveDefinitionSaga from "./Masters/LeaveDefinition/saga";
import HolidaySaga from "./Masters/Holiday/saga";

// Reports
import reportsSaga from "./reports/saga";
// System APIs
import menuItemSaga from "./system/saga";

// Side Menu
import AttendanceSaga from "./sideMenu/attendance/saga";
import LeaveSaga from "./sideMenu/leave/saga";
import OnSiteSaga from "./sideMenu/onSite/saga";
import LateSaga from "./sideMenu/late/saga";
import TaskManagementSaga from "./sideMenu/taskManagement/saga";
import LateRequestSaga from "./sideMenu/lateRequests/saga";
import MonthlyAttendanceSaga from "./sideMenu/monthlyAttendance/saga";
import AttendanceCorrectionSaga from "./sideMenu/attendanceCorrection/saga";
import MonthlyPerformanceSaga from "./sideMenu/monthlyPerformance/saga";

// Dashboard
import dashboardSaga from "./dashboard/saga";
import ShiftAllocationSaga from "./sideMenu/shiftAllocation/saga";

export default function* rootSaga() {
  yield all([
    // login
    loginSaga(),

    //notification
    notificationSaga(),

    //userProfile
    userProfileSaga(),

    // Settings
    ManageUsersSaga(),
    ManageProfileSaga(),
    ManagersSaga(),
    LocationManagerSaga(),
    ApprovalSaga(),
    ImportExportSaga(),
    BackupDatabaseSaga(),
    ManageCompanySaga(),
    AppSettingsSaga(),

    // mini
    miniSaga(),

    // Masters
    DepartmentSaga(),
    DesignationSaga(),
    RoleSaga(),
    OrganizationSaga(),
    LocationSaga(),
    LateReasonsSaga(),
    CitySaga(),
    StateSaga(),
    LeavereasonsSaga(),
    OnSiteLocationSaga(),
    ShiftSaga(),
    TaskStatus(),
    TemplateTaskSaga(),
    LeaveDefinitionSaga(),
    HolidaySaga(),

    //Reports
    reportsSaga(),
    // system APIs
    menuItemSaga(),

    // Side Menu
    AttendanceSaga(),
    LateSaga(),
    LeaveSaga(),
    OnSiteSaga(),
    TaskManagementSaga(),
    TaskTemplateCreationSaga(),
    LateRequestSaga(),
    MonthlyAttendanceSaga(),
    MonthlyPerformanceSaga(),
    AttendanceCorrectionSaga(),
    ShiftAllocationSaga(),

    // Dashboard
    dashboardSaga(),
  ]);
}
