import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getShifts,
  shiftListSuccessful,
  getShiftByIDSuccessful,
  isModelVisible,
  InputChangeValue,
} from "./shiftSlice";
// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* shiftGet({ payload }) {
  console.log("shiftGet", payload);
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/shifts/", params);
    yield put(shiftListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddShift({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(postAdd, "/masters/shifts/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Shift Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getShifts({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Shift. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/shifts/" + payload.obj.id + "/",
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Shift Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getShifts({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Shift. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* ShiftByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/shifts/" + payload + "/",
      {}
    );
    console.log("response.data", response.data);
    yield put(getShiftByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DeleteShift({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/shifts/" + payload + "/");

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Shift has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getShifts({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry!   Shift has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetShifts() {
  yield takeEvery("shifts/getShifts", shiftGet);
}
export function* watchShiftAdd() {
  yield takeEvery("shifts/shiftAdd", AddShift);
}
export function* watchGetShiftByID() {
  yield takeEvery("shifts/getShiftByID", ShiftByID);
}
export function* watchShiftDelete() {
  yield takeEvery("shifts/shiftDelete", DeleteShift);
}

function* ShiftSaga() {
  yield all([
    fork(watchGetShifts),
    fork(watchShiftAdd),
    fork(watchGetShiftByID),
    fork(watchShiftDelete),
  ]);
}

export default ShiftSaga;
