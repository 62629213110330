import React, { useState } from "react";
import { Card, Row, Col, DatePicker, Typography, Select } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";

export default function AttendanceCard(props) {
  const { Text, Link } = Typography;
  const [yAxisMaxValue, setYaxisMaxValue] = useState(10);

  var list = [
    { value: 10, name: "10" },
    { value: 15, name: "15" },
    { value: 20, name: "20" },
    { value: 25, name: "25" },
    { value: 30, name: "30" },
  ];

  return (
    <Card
      className="hp-border-color-black-40 hp-h-100"
      style={{
        height: 400,
      }}
    >
      <Row gutter={[12, 12]}>
        <Col className="hp-mb-0" span={24}>
          <Text strong>{props.title}</Text>
        </Col>

        <Col span={24}>
          <div id="chart">
            <Chart
              // options={data.options}
              // series={data.series}
              // type="bar"
              height={350}
              // legend="legend"

              options={{
                chart: {
                  id: "chart",
                  fontFamily: "Manrope, sans-serif",
                  type: "bar",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                labels: {
                  style: {
                    fontSize: "14px",
                  },
                },

                dataLabels: {
                  enabled: false,
                },

                grid: {
                  borderColor: "#DFE6E9",
                  row: {
                    opacity: 0.5,
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: "60%",
                    endingShape: "rounded",
                  },
                  colors: {
                    backgroundBarColors: ["#0063F7", "#00F7BF"],
                  },
                },

                stroke: {
                  show: true,
                  width: 5,
                  colors: ["transparent"],
                },
                xaxis: {
                  axisTicks: {
                    show: false,
                    borderType: "solid",
                    color: "#78909C",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  tickPlacement: "between",
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                  },
                  categories: props.categoryData?.map((d) => d),
                },
                responsive: [
                  {
                    breakpoint: 426,
                    options: {
                      legend: {
                        itemMargin: {
                          horizontal: 16,
                          vertical: 8,
                        },
                      },
                    },
                  },
                ],
                legend: {
                  horizontalAlign: "right",
                  offsetX: 100,
                  position: "top",
                  markers: {
                    radius: 12,
                  },
                },
                yaxis: {
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                    formatter: (value) => {
                      return value;
                    },
                  },

                  // min: 0,
                  // max: yAxisMaxValue,
                  // tickAmount: 5,
                },
              }}
              series={props.data.map((d) => d)}
              type="bar"
              legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
