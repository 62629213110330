import React, { useState, useEffect } from "react";
import { Card, Row, Col, Typography } from "antd";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

export default function StatisticsCard(props) {
  const { Text, Link } = Typography;

  const { type, dark = false, data } = props;

  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Row gutter={(12, 12)}>
        <Col span={24} className="hp-mb-6">
          <Text strong>{props.title}</Text>
        </Col>

        <Col span={24}>
          <Row gutter={(12, 12)}>
            <Col span={12}>
              <Text className="h5" strong>
                {data?.count}
              </Text>
            </Col>
            <Col span={12}>
              <div
                className={`${
                  dark ? `hp-bg-black-10` : `hp-bg-${data?.type}-4`
                } hp-border-radius hp-bg-dark-90 hp-text-right hp-p-8 hp-position-relative hp-overflow-hidden hp-d-flex hp-d-flex-justify-center hp-d-flex-column`}
                style={{
                  height: 45,
                }}
              >
                <span
                  className={`${
                    dark ? `hp-bg-black-100` : `hp-bg-${data?.type}-1`
                  } line hp-position-absolute-top-center-left`}
                  style={{
                    width: 5,
                    height: "70%",
                    borderRadius: 10,
                    left: -2,
                  }}
                ></span>

                <div className="hp-d-flex-center hp-d-flex-end">
                  <svg
                    className={
                      dark ? `hp-fill-black-100` : `hp-fill-${data?.type}-1`
                    }
                    width="10"
                    height="9"
                    viewBox="0 0 10 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {data?.arrowDown ? (
                      <path d="M6.724 7.66931L0.986 1.93131L1.92867 0.988647L7.66667 6.72598L7.66667 1.66931L9 1.66931L9 9.00265L1.66667 9.00265L1.66667 7.66931L6.724 7.66931V7.66931Z" />
                    ) : (
                      <path d="M7.66944 2.276L1.93144 8.014L0.98877 7.07133L6.7261 1.33333H1.66944V0H9.00277V7.33333H7.66944V2.276V2.276Z" />
                    )}
                  </svg>

                  <span
                    className={`${
                      dark
                        ? `hp-text-color-black-100`
                        : `hp-text-color-${data?.type}-1`
                    } hp-p1-body hp-ml-8`}
                  >
                    {data?.percentage && <>{data?.percentage}%</>}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
