import React from "react";
import { Card, Row, Col } from "antd";
import { connect } from "react-redux";
import PageTitle from "../../layout/components/content/page-title";
import StatisticsCard from "./statisticsCard";
import TaskListCard from "./taskListCard";
import AttendanceCard from "./attendanceCard";
import OverViewCard from "./overviewCard";

import {
  getEmployeeCount,
  getTaskList,
  getTaskOverView,
  clearData,
  getRequests,
  getAttendanceData,
  getUserAttendance,
  getUserTaskOverView,
  getUserRequests,
} from "../../store/dashboard/dashboardSlice";
import { useEffect } from "react";

function DashboardHome(props) {
  useEffect(() => {
    props.clearData();
    if (props.userAccessList?.indexOf("System.dashboard_2")) {
      props.getEmployeeCount();

      props.getTaskOverView();
      props.getRequests();
      props.getAttendanceData();
    }
    props.getTaskList({
      page: 1,
      page_size: 10,
    });
    if (props.userAccessList?.indexOf("System.dashboard_1")) {
      props.getUserAttendance();
      props.getUserTaskOverView();
      props.getUserRequests();
    }
  }, []);

  return (
    <Row gutter={[32, 32]} className="hp-mb-32">
      <Col flex="1" className="hp-overflow-hidden">
        <PageTitle
          pageTitle={
            props.userAccessList?.indexOf("System.dashboard_1") !== -1
              ? "User Dashboard"
              : props.userAccessList?.indexOf("System.dashboard_2") !== -1
              ? "Manager Dashboard"
              : "Dashboard"
          }
          goBack={false}
        />

        {props.userAccessList?.indexOf("System.dashboard_2") !== -1 && (
          <>
            <Row gutter={[12, 12]} className="hp-mb-10">
              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard
                  title="Total Employees"
                  data={props.employeeCount}
                />
              </Col>
              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard title="Today's Punch" data={props.presents} />
              </Col>
              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard title="Today's Unpunch" data={props.absent} />
              </Col>

              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard title="Today Lates" data={props.late} />
              </Col>

              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard title="Today Leaves" data={props.leave} />
              </Col>

              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard title="Today Onsite" data={props.onsite} />
              </Col>

              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard
                  title="Revised Presents"
                  data={props.revised_present}
                />
              </Col>

              <Col span={24} xs={24} sm={12} md={6} xl={6}>
                <StatisticsCard
                  title="Revised Absents"
                  data={props.revised_absent}
                />
              </Col>
            </Row>

            <Row gutter={[12, 12]} className="hp-mb-10">
              <Col span={24} xs={24} sm={24} md={24} xl={12}>
                <AttendanceCard
                  title="Reporting Users Attendance"
                  categoryData={props.categoty}
                  data={props.attendanceData}
                />
              </Col>

              <Col span={24} xs={24} sm={24} md={24} xl={12}>
                <Row gutter={[12, 12]}>
                  <Col span={12} xs={12} sm={12} md={12} xl={12}>
                    <OverViewCard
                      title="Reporting Users Task Overview"
                      data={props.taskOVerviewData}
                    />
                  </Col>
                  <Col span={12} xs={12} sm={12} md={12} xl={12}>
                    <OverViewCard
                      title="Reporting Users Request"
                      data={props.requestsData}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {/* User Attendance */}
        {props.userAccessList?.indexOf("System.dashboard_1") !== -1 && (
          <Row gutter={[12, 12]} className="hp-mb-10">
            <Col span={24} xs={24} sm={24} md={24} xl={12}>
              <AttendanceCard
                title="Self Attendance"
                categoryData={props.userCategoty}
                data={props.userAttendanceData}
              />
            </Col>

            <Col span={24} xs={24} sm={24} md={24} xl={12}>
              <Row gutter={[12, 12]}>
                <Col span={12} xs={12} sm={12} md={12} xl={12}>
                  <OverViewCard
                    title="Self Task Overview"
                    data={props.userTaskOVerviewData}
                  />
                </Col>
                <Col span={12} xs={12} sm={12} md={12} xl={12}>
                  <OverViewCard
                    title="Self Request"
                    data={props.userRequestsData}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row gutter={[12, 12]}>
          <Col span={24} xs={24} sm={24} md={24} xl={24}>
            <TaskListCard data={props.taskList} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    employeeCount: dashboard.employeeCount,
    presents: dashboard.presents,
    absent: dashboard.absent,
    late: dashboard.late,
    leave: dashboard.leave,
    onsite: dashboard.onsite,
    revised_present: dashboard.revised_present,
    revised_absent: dashboard.revised_absent,

    taskList: dashboard.taskList,
    taskOVerviewData: dashboard.taskOVerviewData,
    requestsData: dashboard.requestsData,
    attendanceData: dashboard.attendanceData,
    categoty: dashboard.categoty,
    userAccessList: system.userAccessList,

    userAttendanceData: dashboard.userAttendanceData,
    userCategoty: dashboard.userCategoty,
    userTaskOVerviewData: dashboard.userTaskOVerviewData,
    userRequestsData: dashboard.userRequestsData,
  };
}

export default connect(mapStateToProps, {
  getEmployeeCount,
  getTaskList,
  getTaskOverView,
  clearData,
  getRequests,
  getAttendanceData,

  getUserAttendance,
  getUserTaskOverView,
  getUserRequests,
})(DashboardHome);
