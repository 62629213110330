import { createSlice } from "@reduxjs/toolkit";

export const templateTaskSlice = createSlice({
  name: "templateTask",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    model: false,
    error_msg: null,
    listCount: 0,
    templateTasklist: [],

    templateTaskParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rowdata: {},
  },
  reducers: {
    getTemplateTask: (state) => {
      if (state.templateTaskParams?.page == 1) {
        return {
          ...state,
          loading: true,
          templateTasklist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    templateTaskListSuccessful: (state, action) => {
      console.log("templateTaskListSuccessful", action.payload);
      var response = action.payload;
      var list = [];
      if (state.templateTaskParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.templateTasklist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.templateTaskParams?.page_size
      );
      return {
        ...state,
        templateTasklist: list,
        listCount: response.count,
        loading: false,
        templateTaskParams: {
          ...state.templateTaskParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetTemplateTaskParams: (state, action) => {
      return {
        ...state,
        templateTaskParams: action.payload,
      };
    },

    getTemplateTaskByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getTemplateTaskByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
        },
      };
    },

    templateTaskAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    templateTaskAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    
    templateTaskDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getTemplateTask,
  templateTaskListSuccessful,
  SetTemplateTaskParams,
  getTemplateTaskByID,
  getTemplateTaskByIDSuccessful,
  templateTaskAdd,
  templateTaskAddSuccessful,
  isModelVisible,
  InputChangeValue,
  templateTaskDelete,
  apiError,
} = templateTaskSlice.actions;

export default templateTaskSlice.reducer;
