import React, { useState } from "react";
import { Card, Row, Col, DatePicker, Typography, Dropdown, Menu } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";
import { RiMoreFill } from "react-icons/ri";

export default function OverViewCard(props) {
  const { Text, Link } = Typography;

  return (
    <Card
      className="hp-border-color-black-40 "
      style={{
        height: 450,
      }}
    >
      <Row>
        <Col span={24}>
          <Row justify="space-between" align="top">
            <Col className="hp-mb-10">
              <Text  strong>
                {props.title}
              </Text>
              {/* <p className="hp-badge-text">Expenses</p> */}
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div id="earnings-donut-card" className="hp-donut-chart">
            <Chart
              options={{
                chart: {
                  id: "earnings-donut-card",
                  fontFamily: "Manrope, sans-serif",
                  type: "donut",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                // colors: props.data.map((d) => d.color),
                labels: props.data.map((d) => d.title + ":" + d.total),
                dataLabels: {
                  enabled: false,
                },

                plotOptions: {
                  pie: {
                    donut: {
                      size: "80%",
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                        },
                        value: {
                          fontSize: "20px",
                          fontWeight: "regular",
                          color: "B2BEC3",
                          formatter(val) {
                            return val;
                          },
                        },
                        total: {
                          show: true,
                          fontSize: "20px",
                          fontWeight: "regular",
                          // label: "Kitchen",
                          color: "#636E72",

                          formatter: function (w) {
                            return `${w.globals.seriesTotals.reduce((a, b) => {
                              return a + b;
                            }, 0)}`;
                          },
                        },
                      },
                    },
                  },
                },
                responsive: [
                  {
                    breakpoint: 426,
                    options: {
                      legend: {
                        itemMargin: {
                          horizontal: 18,
                          vertical: 8,
                        },
                      },
                    },
                  },
                ],

                legend: {
                  itemMargin: {
                    horizontal: 4,
                    vertical: 2,
                  },
                  horizontalAlign: "left",
                  position: "bottom",
                  fontSize: "14px",
                  inverseOrder: true,
                  markers: {
                    radius: 16,
                  },
                },
              }}
              series={props.data.map((d) => d.total)}
              type="donut"
              height={400}
              legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
