import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    drawer: false,
    loading: false,
    active: false,
    listCount: 0,
    notificationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    // cleardata: (state, action) => {
    //   return {
    //     ...state,
    //     loginError: {},
    //     loginData: {},
    //     message: null,
    //     loading: false,
    //     OTPSend: false,
    //   };
    // },
    updateNotification:(state, action) => {
      // console.log("updateNotification---------", action.payload);
      return {
        ...state,
        loading: true,
        // notificationList:[{...action.payload}, ...state.notificationList ],
        notificationList:[{
          subject:action.payload.message,
          type:action.payload.type,
          created_on: moment(action.payload.ref_id, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mm:ss a")
        }, ...state.notificationList ],
        listCount: state.listCount + 1,
      };
    },
    getNotificationList: (state, action) => {
      if (action.payload?.page == 1) {
        return {
          ...state,
          loading: true,
          listCount: 0,
          notificationList: [],

          notificationParams: {
            ...state.notificationParams,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
      // return {
      //   ...state,
      //   loading: true,
      // };
    },
    notificationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.notificationParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        // notificationList: action.payload.response.results,
        notificationList:[ ...state.notificationList, ...action.payload.response.results],
        loading: false,
        notificationParams: {
          ...state.notificationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetNotificationParams: (state, action) => {
      return {
        ...state,
        notificationParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    // getOTPRequest: (state, action) => {
    //   return {
    //     ...state,
    //     loading: true,
    //     loginError: {},
    //   };
    // },
    // OTPRequestSuccessful: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     loginError: {},
    //     OTPSend: action.payload,
    //   };
    // },
    // logoutUser: (state, action) => {
    //   return { ...state };
    // },
    // logoutUserSuccess: (state, action) => {
    //   return { ...state };
    // },
    // InputChangeValue: (state, action) => {
    //   return {
    //     ...state,
    //     loginData: {
    //       ...state.loginData,
    //       [action.payload.key]: action.payload.value,
    //     },
    //   };
    // },
    notificationActive: (state, action) => {
      return {
        ...state,
        loading: true,
        active: action.payload,
      };
    },
    readNotifition:(state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, loginError: action.payload };
    },
  },
});
export const {
  getNotificationList,
  notificationListSuccessful,
  SetNotificationParams,
  isDrawerVisible,
  // cleardata,
  // checkLogin,
  // loginUserSuccessful,
  // getOTPRequest,
  // OTPRequestSuccessful,
  // logoutUser,
  // logoutUserSuccess,
  // InputChangeValue,
  notificationActive,
  readNotifition,
  updateNotification,
  apiError,
} = notificationSlice.actions;

export default notificationSlice.reducer;
