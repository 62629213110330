import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const manageCompanySlice = createSlice({
  name: "manageCompany",
  initialState: {
    listCount: 0,
    // manageCompanyData: [],
    manageCompanyData: {},
    manageCompanyLogo: {},
    model: false,
    companyLogoLoading: false,
    manageCompanyParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getManageCompany: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    manageCompanySuccessful: (state, action) => {
      return {
        ...state,
        manageCompanyData: {
          // ...action.payload,
          // COMPANY__LOGO: action.payload.COMPANY__LOGO,
          // COMPANY__SMALLLOGO: action.payload.COMPANY__SMALLLOGO,
          COMPANY__NAME: action.payload.COMPANY__NAME,
          COMPANY__SIMPLENAME: action.payload.COMPANY__SIMPLENAME,
          COMPANY__EMAIL: action.payload.COMPANY__EMAIL,
          COMPANY__MOBILE: action.payload.COMPANY__MOBILE,
          COMPANY__ALTERNATEMOBILE: action.payload.COMPANY__ALTERNATEMOBILE,
          COMPANY__WEBSITE: action.payload.COMPANY__WEBSITE,
          COMPANY__GSTNO: action.payload.COMPANY__GSTNO,
          COMPANY__ADDRESS: action.payload.COMPANY__ADDRESS,
        },
        // manageCompanyData: action.payload,
        manageCompanyLogo : {
           COMPANY__LOGO: action.payload.COMPANY__LOGO,
          COMPANY__SMALLLOGO: action.payload.COMPANY__SMALLLOGO,
        },
        loading: false,
      };
    },
    manageCompanyAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    manageCompanyAddSuccessful: (state, action) => {
      return {
        ...state,
        manageCompanyData: {
          // COMPANY__LOGO: action.payload.COMPANY__LOGO,
          // COMPANY__SMALLLOGO: action.payload.COMPANY__SMALLLOGO,
          COMPANY__NAME: action.payload.COMPANY__NAME,
          COMPANY__SIMPLENAME: action.payload.COMPANY__SIMPLENAME,
          COMPANY__EMAIL: action.payload.COMPANY__EMAIL,
          COMPANY__MOBILE: action.payload.COMPANY__MOBILE,
          COMPANY__ALTERNATEMOBILE: action.payload.COMPANY__ALTERNATEMOBILE,
          COMPANY__WEBSITE: action.payload.COMPANY__WEBSITE,
          COMPANY__GSTNO: action.payload.COMPANY__GSTNO,
          COMPANY__ADDRESS: action.payload.COMPANY__ADDRESS,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          // manageCompanyData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        manageCompanyData: {
          ...state.manageCompanyData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getCompanyLogoUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        companyLogoLoading: true,
        // manageCompanyData: {
        //   ...state.manageCompanyData,
        //   [action.payload.imageType]: null,
        // },
      };
    },
    companyLogoUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        companyLogoLoading: false,
        // manageCompanyData: {
        //   ...state.manageCompanyData,
        //   [action.payload.ImageType]: action.payload.response.data,
        //   // [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        // },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getManageCompany,
  manageCompanySuccessful,
  isModelVisible,
  InputChangeValue,
  manageCompanyAdd,
  manageCompanyAddSuccessful,
  getCompanyLogoUpload,
  companyLogoUploadSuccessful,
  apiError,
} = manageCompanySlice.actions;

export default manageCompanySlice.reducer;
