import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";

import {
  apiError,
  getLeaveDefinition,
  LeaveDefinitionSuccessful,
  // taskStatusAddSuccessful,
  getLeaveDefinitionByIDSuccessful,
  isModelVisible,
} from "./leaveDefinitionSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* LeaveDefinition({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/attendance/leave_definition/", params);
    yield put(LeaveDefinitionSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddLeaveDefinition({ payload }) {
  let leaveDefinitionParams = yield select((state) => state.leaveDefinition.leaveDefinitionParams)
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/attendance/leave_definition/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                  Task Leave Definition Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getLeaveDefinition({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Leave Definition. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(taskStatusAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/attendance/leave_definition/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              Leave Definition Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getLeaveDefinition(leaveDefinitionParams));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Leave Definition. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(taskStatusAddSuccessful(null));
    }
  } catch (error) {
    if(error.response.status === 400){
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.duration[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* DeleteLeaveDefinition({ payload }) {
  let leaveDefinitionParams = yield select((state) => state.leaveDefinition.leaveDefinitionParams)
  var stateID = payload;
  try {
    const response = yield call(postDelete, "/attendance/leave_definition/" + stateID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
            Leave Definition has been deleted Successfully!
            </p>
          </div>
        ),
      });
      yield put(getLeaveDefinition(leaveDefinitionParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Leave Definition has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* LeaveDefinitionByID({ payload }) {
  try {
    const response = yield call(getList, "/attendance/leave_definition/" + payload, {});
    yield put(getLeaveDefinitionByIDSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchGetLeaveDefinition() {
  yield takeEvery("leaveDefinition/getLeaveDefinition", LeaveDefinition);
}

export function* watchLeaveDefinitionAdd() {
  yield takeEvery("leaveDefinition/leaveDefinitionAdd", AddLeaveDefinition);
}

export function* watchLeaveDefinitionDelete() {
  yield takeEvery("leaveDefinition/leaveDefinitionDelete", DeleteLeaveDefinition);
}
export function* watchGetLeaveDefinitionByID() {
  yield takeEvery("leaveDefinition/getLeaveDefinitionByID", LeaveDefinitionByID);
}
function* LeaveDefinitionSaga() {
  yield all([
    fork(watchGetLeaveDefinition),
    fork(watchLeaveDefinitionAdd),
    fork(watchLeaveDefinitionDelete),
    fork(watchGetLeaveDefinitionByID),
  ]);
}

export default LeaveDefinitionSaga;
